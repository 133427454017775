import styled, { keyframes } from "styled-components";
const spin = keyframes`

    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
`;
export const AllOrdersContainer = styled.div`
  h3 {
    text-align: center;
    font-weight: 400;
    font-size: 18px;
  }
`;

export const Container = styled.div`
  display: flex;
  font-family: "Montserrat", sans-serif;
  margin-left: 12px;
  height: calc(${(props) => props.innerHeight}px - 88px);
  align-content: start;
  height: calc(100% - 20%);
  flex-flow: wrap;
  margin: 0px;
  padding: 0px 24px;
  justify-content: center;
  overflow-y: auto;
  height: calc(100vh - 320px);

  .text {
    font-size: 18px;
    margin-top: 10%;
  }

  @media only screen and (max-width: 480px) {
    overflow-y: hidden;
  }

  @media only screen and (min-width: 1024px) {
    width: 100% !important;
  }
  .loaderCard {
    place-self: center;
    border: 5px solid;
    border-top: 5px solid #2196f3;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: ${spin} 2s linear infinite;
  }
`;

export const Filters = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 24px;
  justify-content: center;
  margin: 18px 0px 16px 0px;

  .filter-buttons {
    padding: 2px 12px;
    border: 1px solid #c1c1c1;
    box-sizing: border-box;
    box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    margin: 12px;
  }

  .type-button {
    font-weight: 500;
    font-size: 12px;
    border: none;
    background-color: #f4f4f400;
    margin: 2px 4px;
    padding: 2px 4px;
  }

  .type-button-selected {
    border-bottom: solid 2px ${(props) => props.brandcolor};
    font-weight: 600;
  }

  .type-button:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  .checkbox {
    margin-left: 4px;
    font-weight: 500;
  }

  .checkbox input {
    margin-right: 6px;
  }

  form input,
  form div {
    margin: 0px 12px 0px 8px;
    border-radius: 15px;
    cursor: pointer;
    padding: 2px 12px;
    border: solid 1px lightgray;
  }
`;
