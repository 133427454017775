import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Carousel, { consts } from "react-elastic-carousel";
import api from "../../../../services/api";

import * as ordersActions from "../../../../store/modules/orders/actions";

// Styles
import { Container, Filters, AllOrdersContainer } from "../AllOrders/styles";

import OrderThumb from "../OrderThumb";

import { DebounceInput } from "react-debounce-input";

import Loader from "../../../../components/Loader";

const AllOrders = (props) => {
  const isAllOrdersLoading = useSelector(
    (state) => state.ordersReducer.isLoading
  );
  const allOrders = useSelector((state) => state.ordersReducer.orders);

  const [isLoadingOrders, setIsLoadinOrders] = useState(false);
  const contractId = useSelector(
    (state) => state.contractReducer.contract.contractId
  );

  const dispatch = useDispatch();

  const isContractLoading = useSelector(
    (state) => state.contractReducer.contract.isLoading
  );

  const brandContrastColor = useSelector(
    (state) => state.contractReducer.contract.config.brandContrastColor
  );
  const brandColor = useSelector(
    (state) => state.contractReducer.contract.config.brandColor
  );
  const backgroundColor = useSelector(
    (state) => state.contractReducer.contract.config.backgroundColor
  );
  const fontColor = useSelector(
    (state) => state.contractReducer.contract.config.fontColor
  );

  const [searchTerm, setSearchTerm] = useState();
  const [typeList, setTypeList] = useState([]);
  const [currentTypeFilter, setCurrentTypeFilter] = useState("Todos");
  const [page, setPage] = useState(0);
  const [filteredAllOrders, setfilteredAllOrders] = useState();
  const [onlyContractOrders, setOnlyContractOrders] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isMorePages, setIsMoresPages] = useState(true);

  const compareDate = (a, b) => {
    if (a.createdAt < b.createdAt) {
      return 1;
    }
    if (a.createdAt > b.createdAt) {
      return -1;
    }
    return 0;
  };

  const handleScroll = async (element) => {
    if (
      element.target.scrollHeight - element.target.scrollTop <
      element.target.clientHeight + 1
    ) {
      if (!isSearching) {
        setIsSearching(true);
        if (isMorePages) {
          await getNextPage();
        }
        setIsSearching(false);
      }
    }
  };

  const getNextPage = async () => {
    let allOrdersHelper = filteredAllOrders;
    const respOrders = await api.get(
      `ordersprofessional?ordersprofessional?${
        searchTerm ? `search=${searchTerm}` : ""
      }${currentTypeFilter !== "Todos" ? `&type=${currentTypeFilter}` : ""}${
        onlyContractOrders ? `&contract=${contractId}` : ""
      }&page=${page + 1}`
    );

    if (respOrders.data.success) allOrdersHelper = respOrders.data.orders;

    setfilteredAllOrders(
      filteredAllOrders
        .concat(allOrdersHelper)
        .filter((item) => item.status != "deleted")
    );
    setPage(page + 1);
    if (allOrdersHelper.length === 0) {
      setIsMoresPages(false);
    }
  };

  const sortArray = (orderArray) => {
    return orderArray.sort(compareDate);
  };

  useEffect(() => {
    isAllOrdersLoading &&
      !isContractLoading &&
      dispatch(ordersActions.getOrders(null));

    async function fetchTypes() {
      const typesResponse = await api
        .get("/room/all/type")
        .catch((e) => console.log(e));
      const typesHelper = typesResponse.data.type;

      setTypeList(typesHelper.sort());
    }
    fetchTypes();

    setfilteredAllOrders(
      sortArray(allOrders).filter((item) => item.status != "deleted")
    );
  }, [isContractLoading, isAllOrdersLoading, allOrders]);

  const handleFilterChange = async () => {
    window.scrollTo(0, 0);
    setIsLoadinOrders(true);
    api
      .get(
        `ordersprofessional?${searchTerm ? `search=${searchTerm}` : ""}${
          currentTypeFilter !== "Todos" ? `&type=${currentTypeFilter}` : ""
        }${onlyContractOrders ? `&contract=${contractId}` : ""}&page=0`
      )
      .then((response) => {
        const newOrders = response.data.orders;
        setPage(0);
        setIsMoresPages(true);
        setfilteredAllOrders(
          newOrders.filter((item) => item.status != "deleted")
        );
        setIsLoadinOrders(false);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    handleFilterChange();
  }, [searchTerm, currentTypeFilter, onlyContractOrders]);

  const onOrderThumbClick = (order) => {
    props.history.push({
      pathname: "/simulador",
      state: {
        roomModelId: order.room._id,
        playcanvasId: order.room.playCanvas,
        roomCameras: order.room.cameras,
        orderId: order._id,
        orderImageKey: order.images[0].imageKey,
        showInspirationModal: false,
        isInspiration: true,
      },
    });
  };

  return (
    <AllOrdersContainer>
      <h3 className="subtitle">
        Aqui é onde tudo acontece, se inspire com os ambientes que estão sendo
        criados na plataforma{" "}
        <>
          <strong> CRIA</strong>{" "}
        </>{" "}
      </h3>
      <Filters brandcolor={brandColor}>
        <form
          className="form"
          onSubmit={(event) => {
            event.preventDefault();
          }}
        >
          <label>Buscar</label>
          <DebounceInput
            minLength={2}
            debounceTimeout={300}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Ex.: Calacata Eliane"
          />
        </form>

        <div className="filter-buttons">
          <input
            type="button"
            key="Todos"
            className={
              currentTypeFilter == "Todos"
                ? "type-button type-button-selected"
                : "type-button"
            }
            value="Todos"
            onClick={(e) => setCurrentTypeFilter(e.target.value)}
          />
          {typeList.map((type) => (
            <input
              type="button"
              key={type}
              className={
                currentTypeFilter == type
                  ? "type-button type-button-selected"
                  : "type-button"
              }
              value={type}
              onClick={(e) => setCurrentTypeFilter(e.target.value)}
            />
          ))}
        </div>

        <form>
          <label className="checkbox">
            <input
              type="checkbox"
              onClick={(e) => setOnlyContractOrders(e.target.checked)}
            />
            Apenas minha loja
          </label>
        </form>
      </Filters>
      <Container onScroll={handleScroll}>
        {isAllOrdersLoading || isLoadingOrders ? (
          <Loader borderRadius={"15px"} />
        ) : filteredAllOrders && filteredAllOrders.length <= 0 ? (
          <p className="text">
            Nenhum Atendimento cadastrado na plataforma CRIA
          </p>
        ) : (
          filteredAllOrders &&
          filteredAllOrders.map((room, index) => {
            return (
              <OrderThumb
                key={index}
                id={room._id}
                playCanvasId={room.PlaycanvasId}
                title={room.title}
                status={room.status}
                name={room.customer.name}
                imgSrc={room.images[0].imageSrc}
                onThumbClick={() => onOrderThumbClick(room)}
                showDetails={false}
              ></OrderThumb>
            );
          })
        )}
        {isSearching && filteredAllOrders && <div className="loaderCard"></div>}
      </Container>
    </AllOrdersContainer>
  );
};

export default AllOrders;
