import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as loginActions from "./../../../../store/modules/login/actions";
import PlaycanvasContext from "../../../../components/playcanvasContext";
import api from "../../../../services/api";

// Styles
import {
  Container,
  SelectFaceAlert,
  LastAppliedList,
  ContainerLoading,
} from "./styles";

import selectFaceIcon from "./../../../../assets/pincel-preto.png";

// Components
import MaterialsTable from "./components/MaterialsTable";
import SearchInput from "./components/SearchInput";
import TypesTable from "./components/TypesTable";
import MaterialsFilter from "./components/MaterialsFilter";
import OrderByModal from "./components/OrderByModal";
import FilterBar from "./../../components/FilterBar";

const Materials = (props) => {
  const [allMaterials, setAllMaterials] = useState([]);
  const [isLoadingTypes, setIsLoadingTypes] = useState(true);
  const [selectedTypeMaterials, setSelectedTypeMaterials] = useState({
    type: "",
    category: "",
  });
  const [filteredMaterials, setFilteredMaterials] = useState([]);

  const [filters, setFilters] = useState({
    color: [],
    brand: [],
    format: [],
    surface: [],
  });
  const [page, setPage] = useState(0);

  const [isNextPage, setIsNextPage] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [orderBy, setOrderBy] = useState("Ordenar");
  const [displayFiltersModal, setDisplayFiltersModal] = useState(false);
  const [displayOrderBy, setDisplayOrderBy] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [typeAndReproduction, setTypeAndReproduction] = useState([]);

  const onboarding = useSelector((state) => state.loginReducer.onboarding);
  const fontColor = useSelector(
    (state) => state.contractReducer.contract.config.fontColor
  );
  const backgroundColor = useSelector(
    (state) => state.contractReducer.contract.config.backgroundColor
  );
  const brandColor = useSelector(
    (state) => state.contractReducer.contract.config.brandColor
  );
  const contractId = useSelector(
    (state) => state.contractReducer.contract.contractId
  );
  const user = useSelector((state) => state.loginReducer.user);
  //const allMaterials = useSelector(state => state.contractMaterials.data)

  const dispatch = useDispatch();

  const playcanvas = useContext(PlaycanvasContext);

  const getNextPage = () => {
    const filtersJSON = JSON.stringify(filters);
    const isFilter =
      filters.brand.length > 0 ||
      filters.color.length > 0 ||
      filters.format.length > 0 ||
      filters.surface.length > 0;
    const query = `${
      searchText ? `searchString=${searchText}` : "filterOnlyBySearch=false"
    }&${
      selectedTypeMaterials.type != ""
        ? `reproduction=${selectedTypeMaterials.type}&category=${selectedTypeMaterials.category}`
        : `filterOnlyBySearch=true`
    }&${isFilter ? `isFilter=true&filters=${filtersJSON}` : `isFilter=false`}`;
    setIsSearching(true);
    api
      .get(
        `material/contract/${contractId}/reduced?active=true&page=${
          page + 1
        }&${query}`
      )
      .then((response) => {
        setFilteredMaterials(filteredMaterials.concat(response.data.materials));
        setIsSearching(false);
      })
      .catch((e) => {
        if (e.response) {
          if (e.response.status == 404) {
            setIsNextPage(false);
          } else {
            console.log(e);
          }
        } else {
          console.log(e);
        }
        setIsSearching(false);
      });
    setPage((page) => page + 1);
  };

  useEffect(() => {
    if (contractId) {
      api
        .get(`material/contract/${contractId}/reproduction`)
        .then((data) => {
          setTypeAndReproduction(data.data.types);
          setIsLoadingTypes(false);
        })
        .catch((error) => console.log(error));
    }
  }, []);

  useEffect(() => {
    playcanvas.connectToIframe();
  }, []);

  useEffect(() => {
    setFilteredMaterials([]);
    if (selectedTypeMaterials || searchText) {
      const filtersJSON = JSON.stringify(filters);
      const isFilter =
        filters.brand.length > 0 ||
        filters.color.length > 0 ||
        filters.format.length > 0 ||
        filters.surface.length > 0;
      const query = `${
        searchText ? `searchString=${searchText}` : "filterOnlyBySearch=false"
      }&${
        selectedTypeMaterials.type != ""
          ? `reproduction=${selectedTypeMaterials.type}&category=${selectedTypeMaterials.category}`
          : `filterOnlyBySearch=true`
      }&${
        isFilter ? `isFilter=true&filters=${filtersJSON}` : `isFilter=false`
      }&orderBy=${orderBy}`;
      setIsSearching(true);
      api
        .get(
          `material/contract/${contractId}/reduced?active=true&page=0&${query}`
        )
        .then((response) => {
          setFilteredMaterials(response.data.materials);
          setIsSearching(false);
          setIsNextPage(true);
        })
        .catch((e) => {
          if (e.response && e.response.status == 404) {
            setFilteredMaterials([]);
          }
          console.log(e);
          setIsSearching(false);
          setIsNextPage(false);
        });
    }
    setPage(0);
  }, [filters, selectedTypeMaterials, searchText, orderBy]);

  useEffect(() => {
    setDisplayOrderBy(false);
  }, [orderBy, props.modal]);

  const handleClickBack = () => {
    setFilters({
      color: [],
      brand: [],
      format: [],
      surface: [],
    });
    setSearchText("");
    setOrderBy("Ordenar");
    setSelectedTypeMaterials({
      type: "",
      category: "",
    });
  };

  const handleTypesThumbClick = (selected) => {
    setSelectedTypeMaterials({
      type: selected.type.description,
      category: selected.category,
    });
    setSearchText("");
    onboarding === 5 && dispatch(loginActions.setOnboarding(6));
  };

  return (
    <>
      {displayFiltersModal ? (
        <MaterialsFilter
          materials={selectedTypeMaterials}
          modal={displayFiltersModal}
          setModal={setDisplayFiltersModal}
          setFilters={setFilters}
          filters={filters}
          contractId={contractId}
          selectedTypeMaterials={selectedTypeMaterials}
        ></MaterialsFilter>
      ) : (
        <>
          {!isLoadingTypes ? (
            <>
              <Container fontColor={fontColor}>
                {user.type !== "professional" && !props.selectedFace && (
                  <SelectFaceAlert
                    fontColor={fontColor}
                    backgroundColor={backgroundColor}
                  >
                    <div>
                      <div className="icon-div">
                        <img src={selectFaceIcon}></img>
                      </div>
                      Para aplicar um material, primeiro selecione uma
                      superfície.
                    </div>
                  </SelectFaceAlert>
                )}
                {props.lastAppliedMaterials.length > 0 && (
                  <LastAppliedList fontColor={fontColor}>
                    <h2>Recentes</h2>
                    <div>
                      {props.lastAppliedMaterials.map((material, index) => (
                        <img
                          key={index}
                          src={
                            material.miniThumbPath
                              ? material.miniThumbPath
                              : material.thumbPath
                          }
                          onClick={() =>
                            props.handleMaterialThumbClick(material)
                          }
                        ></img>
                      ))}
                    </div>
                  </LastAppliedList>
                )}
                <FilterBar
                  mainContent={
                    <>
                      <SearchInput
                        action={setSearchText}
                        value={searchText}
                        setFiltersModal={setDisplayFiltersModal}
                        margin={"12px 8px 12px 0"}
                        width={selectedTypeMaterials ? "110px" : "100%"}
                      />
                      {selectedTypeMaterials.type != "" && (
                        <>
                          <button
                            className={
                              filters.color.length > 0 ||
                              filters.brand.length > 0 ||
                              filters.format.length > 0
                                ? "selected"
                                : ""
                            }
                            onClick={() =>
                              setDisplayFiltersModal(!displayFiltersModal)
                            }
                          >
                            Filtros
                            <i className="fas fa-filter"></i>
                          </button>
                          <button
                            className={orderBy === "Ordenar" ? "" : "selected"}
                            onClick={() => setDisplayOrderBy(!displayOrderBy)}
                          >
                            {orderBy}
                            <i className="fas fa-chevron-down"></i>
                          </button>
                        </>
                      )}
                    </>
                  }
                ></FilterBar>
                {selectedTypeMaterials.type != "" ? (
                  <>
                    <nav className="navbar">
                      <i
                        onClick={() => handleClickBack()}
                        className="fas fa-arrow-left"
                      ></i>
                      <h1>{selectedTypeMaterials.type}</h1>
                    </nav>
                    <MaterialsTable
                      setModal={props.setModal}
                      data={filteredMaterials}
                      getNextPage={getNextPage}
                      isLoading={isSearching}
                      isNextPage={isNextPage}
                      onMaterialClick={props.handleMaterialThumbClick}
                    />
                    <OrderByModal
                      display={displayOrderBy}
                      fullScreen={props.fullScreen}
                      title={"Ordenar por"}
                      fitContent={true}
                      onCloseClick={() => setDisplayOrderBy(false)}
                      mainContent={
                        <>
                          <div
                            onClick={() => setOrderBy("Mais aplicados")}
                            className={
                              orderBy === "Mais aplicados"
                                ? "option selected"
                                : "option"
                            }
                          >
                            Mais aplicados
                          </div>
                          <div
                            onClick={() => setOrderBy("Ordem alfabética")}
                            className={
                              orderBy === "Ordem alfabética"
                                ? "option selected"
                                : "option"
                            }
                          >
                            Ordem alfabética
                          </div>
                          <div
                            onClick={() => setOrderBy("Cor")}
                            className={
                              orderBy === "Cor" ? "option selected" : "option"
                            }
                          >
                            Cor
                          </div>
                        </>
                      }
                    ></OrderByModal>
                  </>
                ) : (
                  <>
                    {searchText.length > 0 && (
                      <MaterialsTable
                        setModal={props.setModal}
                        data={filteredMaterials}
                        getNextPage={getNextPage}
                        isLoading={isSearching}
                        isNextPage={isNextPage}
                        onMaterialClick={props.handleMaterialThumbClick}
                      />
                    )}
                    {searchText.length === 0 && allMaterials && (
                      <TypesTable
                        data={typeAndReproduction}
                        action={handleTypesThumbClick}
                      />
                    )}
                  </>
                )}
              </Container>
            </>
          ) : (
            <>
              <ContainerLoading
                brandColor={brandColor}
                backgroundColor={backgroundColor}
              ></ContainerLoading>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Materials;
