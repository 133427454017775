import React, { useState, useEffect } from "react";
import Loader from "../../../../../../components/Loader";
import { useSelector } from "react-redux";

// Styles
import { Container, Text } from "./styles";

const MaterialsTable = (props) => {
  const [materialsLoading, setMaterialsLoading] = useState(27);

  const onboarding = useSelector((state) => state.loginReducer.onboarding);

  const backgroundColor = useSelector(
    (state) => state.contractReducer.contract.config.backgroundColor
  );
  const fontColor = useSelector(
    (state) => state.contractReducer.contract.config.fontColor
  );

  const { getNextPage, isLoading, isNextPage } = props;
  const handleScroll = (element) => {
    if (element.scrollHeight - element.scrollTop <= element.clientHeight + 1) {
      if (!isLoading && isNextPage) {
        getNextPage();
      }
    }
  };

  return (
    <>
      {props.data.length === 0 ? (
        isLoading ? (
          <Container>
            <Loader />
          </Container>
        ) : (
          <Text fontColor={fontColor}>Nenhum material encontrado.</Text>
        )
      ) : (
        <Container
          onScroll={(e) => handleScroll(e.target)}
          backgroundColor={backgroundColor}
          fontColor={fontColor}
        >
          {props.data.map((item, index) => {
            return (
              <button
                key={item._id}
                className={
                  onboarding === 6 && index === 0
                    ? "list__item active"
                    : "list__item"
                }
                onClick={() => props.onMaterialClick(item)}
              >
                <img
                  className="list__item__img"
                  loading="eager"
                  src={item.miniThumbPath ? item.miniThumbPath : item.thumbPath}
                  alt={item.description}
                />
                <h2 className="list__item__title">{item.description}</h2>
                <p className="list__item__text">{item.brand}</p>
                <p className="list__item__text">
                  {item.format} - {item.surface.description}
                </p>
              </button>
            );
          })}
          {isLoading && (
            <button className="list__item">
              <Loader />
            </button>
          )}
        </Container>
      )}
    </>
  );
};

export default MaterialsTable;
