import React, { useState, useEffect, useRef } from 'react'
import YouTube from 'react-youtube'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel';

// Styles
import { Container, Header, TermsModal } from './styles'

import * as userRoomsActions from './../../store/modules/userRooms/actions'

const Tutorial = () => {

    ReactPixel.init('585731899262448', {}, { debug: true, autoConfig: false });
    ReactPixel.pageView();
    ReactPixel.fbq('track', 'PageView');
    ReactPixel.fbq('trackCustom', 'PV_Tutorial');

    const [innerHeight, setInnerHeight] = useState(window.innerHeight)
    const brandContrastColor = useSelector(state => state.contractReducer.contract.config.brandContrastColor)
    const brandColor = useSelector(state => state.contractReducer.contract.config.brandColor)
    const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
    const fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)
    const alertColor = useSelector(state => state.contractReducer.contract.config.alertColor)
    const logoPath = useSelector(state => state.contractReducer.contract.config.logoPath)

    const history = useHistory()

    const dispatch = useDispatch()

    const refButton = useRef(null)

    const videoOpts = {
        height: '405',
        width: '720',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
        },
      }

    useEffect(() => {
        function onKeyup(e) {
            if (e.key === 'Enter') {
                refButton.current.click()
            }
        }
        window.addEventListener('keyup', onKeyup)

        return () => window.removeEventListener('keyup', onKeyup)
    }, [])

    useEffect(() => {
        const handleResize = () => setInnerHeight(window.innerHeight)
        window.addEventListener("resize", handleResize)
        
        return () => window.removeEventListener("resize", handleResize)
    }, []);

    return (
        <Container innerHeight={innerHeight} backgroundColor={backgroundColor} fontColor={fontColor} brandColor={brandColor} brandContrastColor={brandContrastColor} alertColor={alertColor}>
            <Header>
                <img src={logoPath} alt="Logotipo"></img>
            </Header>
            <h1>Estamos quase lá...</h1>
            <h3>
                Preparamos uma pequena explicação para sua melhor experiência<br/>
                durante o primeiro contato com a nossa plataforma
            </h3>
            {/*<h2>
                É rápidinho!
            </h2>*/}
           
            <YouTube className="div-video" videoId="n-6OY75qt3k" opts={videoOpts} onEnd={() => history.push("/")}>

            </YouTube>

            <a className="submit-button" onClick={() => history.push("/")} onFinish={() => history.push("/")} >COMEÇAR</a>
            
        </Container>
    )
}

export default Tutorial