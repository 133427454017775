import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  width: 100vw;
  height: 90vh;
  background-color: ${(props) => props.backgroundColor};
  box-shadow: 0 0.2px 4px rgba(0, 0, 0, 0.2);
  display: ${(props) => props.display};
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  opacity: 1;
  z-index: 4;
  margin: 0px;
  bottom: 0px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;

  @media only screen and (min-width: 768px) {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 1px);
    width: 50vw;
    height: 72vh;
    border-radius: 20px;

    div.content h3,
    div.content p,
    div.content small.alert,
    div.content input[type="text"] {
      width: 348px !important;
    }

    div.material-list {
      padding: 24px;
    }
  }

  h1 {
    width: 100%;
    margin: 48px 0px 24px 0px;
    color: ${(props) => props.fontColor};
    text-align: center;
    font-size: 2.1em;
  }

  .empty-list {
    text-align: center;
    margin-top: 20%;
    color: #515151;
    font-size: 1.5em;
  }

  div.material-list {
    width: 100%;
    height: 75%;
    padding: 0 16px 16px;
    overflow-y: auto;
  }

  div.material-list-image {
    width: 100%;
    position: relative;
    margin-bottom: 16px;

    > img {
      width: 100%;
      border-radius: 8px;
    }
  }

  i.close {
    position: absolute;
    right: 4px;
    top: 1px;
    font-size: 20px;
    margin: 8px;
    padding: 6px;
    color: ${(props) => props.fontColor};
    border-radius: 20px;
    :hover {
      opacity: 0.7;
      box-shadow: 3px 3px 3px 0px #666;
      cursor: pointer;
    }
  }

  button {
    display: flex;
    position: relative;
    width: 138px;
    height: 32px;
    border-radius: 16px;
    border: none;
    font-size: 12px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    justify-content: center;
    align-items: center;
    margin: auto 4px auto 4px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: ${(props) => props.brandContrastColor};
    background-color: ${(props) => props.brandColor};
  }

  button div.buttonload {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #0048ff;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 2s linear infinite;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes showImage {
    0% {
      height: 30%;
      margin-bottom: 16px;
    }
    100% {
      height: 100%;
      margin-bottom: -16px;
    }
  }

  @keyframes ajustImage {
    0% {
      top: -30%;
    }
    100% {
      top: 0%;
    }
  }

  @media only screen and (min-height: 1750px) and (max-width: 1080px) {
    width: 65vw!important;
    height: 90vh!important;

    .material-list{
      height: 90%!important;
    }
  }

`;
