import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Image,
} from "pure-react-carousel";

import * as loginActions from "./../../../../store/modules/login/actions";

import api from "../../../../services/api";

// Styles
import { Container } from "./styles";

// Components
import SectionTopBar from "../../../../components/SectionTopBar";
import TipsModal from "../../../../components/TipsModal";
import ModalBackground from "../../../../components/ModalBackground";
import RoomTypeThumb from "./components/RoomTypeThumb";
import RoomModelThumb from "./components/RoomModelThumb";
import CircularProgress from "@material-ui/core/CircularProgress";
import GenericModal from "../../../../components/GenericModal";

const RoomsList = (props) => {
  const [roomType, setRoomType] = useState(false);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const [ready, setReady] = useState(false);

  const onboarding = useSelector((state) => state.loginReducer.onboarding);

  let [roomsNotAut, setRoomsNotAut] = useState([]);

  let [rooms, setRooms] = useState(props.roomsData);

  const brandColor = useSelector(
    (state) => state.contractReducer.contract.config.brandColor
  );
  const backgroundColor = useSelector(
    (state) => state.contractReducer.contract.config.backgroundColor
  );
  const fontColor = useSelector(
    (state) => state.contractReducer.contract.config.fontColor
  );
  const roomsContract = useSelector(
    (state) => state.contractReducer.contract.rooms
  );
  let TestUser = useSelector((state) => state.loginReducer.user.test);

  const [readyRooms, setReadyRooms] = useState(false);
  const [planoDisplay, setPlanoDisplay] = useState(false);
  const [readyRoomsLoad, setReadyRoomsLoad] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => setInnerHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (onboarding) {
      dispatch(loginActions.setOnboarding(true));
    }
  }, []);

  const onTypeThumbClick = (id) => {
    setRoomType(id);
  };

  const onModelThumbClick = (roomModelId, playcanvasId, roomCameras) => {
    props.history.push({
      pathname: "/simulador",
      state: {
        roomModelId: roomModelId,
        playcanvasId: playcanvasId,
        roomCameras: roomCameras,
        showInspirationModal: true,
      },
    });
  };

  const renderRooms = () => {
    let roomsDataAjusted = [];

    rooms.forEach((room) => {
      if (room.title === room.type.description) {
        const findRoom = roomsDataAjusted.find(
          (element) => element.title == room.title
        );

        if (!findRoom) {
          const createRoomType = {
            ...room,
            rooms: [room],
          };
          roomsDataAjusted.push(createRoomType);
        }
      } else {
        const findRoom = roomsDataAjusted.findIndex(
          (element) => element.title == room.type.description
        );

        if (findRoom === -1) {
          const createRoomType = {
            ...room,
            title: room.type.description,
            thumbPath: room.type.thumbPath,
            rooms: [room],
          };
          roomsDataAjusted.push(createRoomType);
        }
      }
    });

    api
      .get(`room`)
      .then(({ data }) => {
        let roomsArray = [];

        data.rooms.map((a) => {
          let exist = false;

          for (const i of roomsDataAjusted) {
            if (a.type._id === i._id || a.type.description === i.title) {
              exist = true;
              let ambientesRooms = [];
              for (const j of data.rooms) {
                if (j.type.description === i.title) {
                  let au = false;
                  roomsContract.map((x) => {
                    if (x === j._id) {
                      au = true;
                      j.authorized = true;
                    }
                  });
                  if (au) ambientesRooms.unshift(j);
                  else ambientesRooms.push(j);
                }
              }
              i.rooms = ambientesRooms;
              break;
            }
          }

          for (const i of roomsArray) {
            if (i.description === a.type.description) {
              exist = true;
              break;
            }
          }

          if (!exist) {
            a.type.authorized = false;
            a.type.title = a.type.description;
            roomsArray.push(a.type);
          } else {
          }
        });

        roomsNotAut = roomsArray;
        setRoomsNotAut(roomsArray);

        let ajuste = [];

        roomsDataAjusted.map((a) => ajuste.push(a));
        roomsNotAut.map((a) => ajuste.push(a));

        setRooms(ajuste);
        rooms = ajuste;

        setReadyRoomsLoad(true);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (TestUser) {
      renderRooms();
      setReady(true);
    }

    if (!readyRooms) {
      renderRooms();
      setReadyRooms(true);
    }
  });

  const onClickLock = () => {
    setPlanoDisplay(true);
    setTimeout(() => {
      setPlanoDisplay(false);
    }, 10000);
  };

  return (
    <Container
      innerHeight={innerHeight}
      backgroundColor={backgroundColor}
      brandColor={brandColor}
      fontColor={fontColor}
    >
      <div className="modalClasse">
        <GenericModal
          display={planoDisplay}
          onCloseClick={() => setPlanoDisplay(false)}
          arrowYPosition={"28vh"}
          arrowXPosition={"6vw"}
          mainContent={
            <div className="modalPlanoClass">
              <h4>{"Ops..."}</h4>
              <br />
              <p>{"Seu plano não tem acesso a este ambiente."}</p>
              <br />
              <p>
                {
                  "Para contratar mais ambientes, entre em contato com a nossa equipe através do chat, no canto inferior direito ou do whatsapp clicando "
                }{" "}
                <a href="https://wa.me/5544999202150" target="_blank">
                  aqui
                </a>
              </p>
            </div>
          }
        ></GenericModal>
        <div
          className={planoDisplay ? "fundo" : ""}
          onClick={() => {
            setPlanoDisplay(false);
          }}
        ></div>
      </div>

      {roomType ? (
        <>
          <SectionTopBar
            title={"Novo ambiente"}
            onClickBack={() => setRoomType(false)}
          ></SectionTopBar>
          <h3>Escolha o modelo do seu novo ambiente</h3>

          <div className="environment-options">
            {rooms
              .filter((type) => roomType == type._id)[0]
              .rooms.map((room, index) => (
                <React.Fragment key={index}>
                  {(room.authorized || TestUser) && (
                    <RoomModelThumb
                      key={index}
                      roomModelIndex={index}
                      typeId={room._id}
                      playCanvasId={room.playCanvas}
                      title={room.title}
                      imgSrc={room.thumbPath}
                      onThumbClick={() =>
                        onModelThumbClick(
                          room._id,
                          room.playCanvas,
                          room.cameras
                        )
                      }
                    ></RoomModelThumb>
                  )}

                  {!room.authorized && !TestUser && (
                    <div className="notAuthClass">
                      <RoomModelThumb
                        key={index}
                        roomModelIndex={index}
                        typeId={room._id}
                        playCanvasId={room.playCanvas}
                        title={room.title}
                        imgSrc={room.thumbPath}
                        onThumbClick={() => onClickLock()}
                      ></RoomModelThumb>
                      {/* <div className="buttonLock2">
                                            <button onClick={onClickLock}>Ver mais</button>
                                        </div> */}
                    </div>
                  )}
                </React.Fragment>
              ))}
          </div>

          {onboarding ? (
            <>
              <ModalBackground></ModalBackground>

              <TipsModal
                title={"Selecione o modelo de ambiente"}
                description={
                  "Escolha o modelo de ambiente que você deseja reformar!"
                }
                arrowUp={true}
                arrowDown={false}
                arrowYPosition={window.innerWidth >= 1024 ? "58vh" : "60vh"}
                arrowXPosition={"0"}
                xPosition={
                  window.innerWidth < 1024
                    ? false
                    : rooms.filter((type) => roomType == type._id)[0].rooms
                        .length === 1
                    ? false
                    : rooms.filter((type) => roomType == type._id)[0].rooms
                        .length === 2
                    ? "calc(38vw - 140px)"
                    : rooms.filter((type) => roomType == type._id)[0].rooms
                        .length === 3
                    ? "calc(26.9vw - 140px)"
                    : "calc(15.7vw - 140px)"
                }
              ></TipsModal>
            </>
          ) : null}
        </>
      ) : (
        <>
          <SectionTopBar
            title={"Novo ambiente"}
            onClickBack={() => props.history.push("/")}
          ></SectionTopBar>
          <h3>Escolha o tipo do seu novo ambiente</h3>

          {window.innerWidth >= 1024 ? (
            <>
              {!readyRoomsLoad && (
                <div className="loadingClass">
                  <CircularProgress />
                </div>
              )}
              {readyRoomsLoad && (
                <div className="environment-options">
                  <CarouselProvider
                    className={
                      onboarding
                        ? "environment-options active"
                        : "environment-options"
                    }
                    naturalSlideWidth={window.innerWidth * 0.086}
                    naturalSlideHeight={200}
                    totalSlides={rooms.length}
                    visibleSlides={rooms.length >= 9 ? 9 : rooms.length}
                    isIntrinsicHeight
                  >
                    <Slider>
                      {rooms.map((room, index) => (
                        <Slide
                          className={
                            room.authorized === false
                              ? "slide notAuthorizedClass"
                              : "slide"
                          }
                          key={index}
                          index={index}
                          onClick={
                            onboarding && index != 0
                              ? () =>
                                  room.authorized === false
                                    ? onClickLock()
                                    : onTypeThumbClick(room._id)
                              : () =>
                                  room.authorized === false
                                    ? onClickLock()
                                    : onTypeThumbClick(room._id)
                          }
                        >
                          <RoomTypeThumb
                            key={index}
                            roomTypeIndex={index}
                            typeId={room._id}
                            title={room.title}
                            MobileThumbSrc={room.mobileThumbPath}
                            DesktopThumbSrc={
                              room.type
                                ? room?.type?.thumbPath
                                : room?.thumbPath
                            }
                          ></RoomTypeThumb>
                          {/* {room.authorized === false &&
                                                        <div className="buttonLock">
                                                            <button onClick={onClickLock}>Ver mais</button>
                                                        </div>
                                                    } */}
                        </Slide>
                      ))}
                    </Slider>
                    {rooms.length > 9 && (
                      <>
                        <ButtonBack className="button-back">
                          <i className="fas fa-arrow-left"></i>
                        </ButtonBack>
                        <ButtonNext className="button-next">
                          <i className="fas fa-arrow-right"></i>
                        </ButtonNext>
                      </>
                    )}
                  </CarouselProvider>
                </div>
              )}
            </>
          ) : (
            <div className="environment-options">
              {rooms.map((room, index) => (
                <RoomTypeThumb
                  key={index}
                  roomTypeIndex={index}
                  typeId={room._id}
                  title={room.title}
                  MobileThumbSrc={room.mobileThumbPath}
                  DesktopThumbSrc={room.thumbPath}
                  onThumbClick={() => onTypeThumbClick(room._id)}
                ></RoomTypeThumb>
              ))}
            </div>
          )}

          {onboarding ? (
            <>
              <ModalBackground></ModalBackground>

              <TipsModal
                title={"Selecione o tipo de ambiente"}
                description={
                  "Escolha o tipo de ambiente que você deseja reformar!"
                }
                arrowUp={window.innerWidth >= 1024 ? false : true}
                arrowDown={false}
                arrowLeft={window.innerWidth >= 1024 ? true : false}
                arrowYPosition={"40vh"}
                arrowXPosition={"-25vw"}
                xPosition={window.innerWidth >= 1024 ? "20vw" : false}
              ></TipsModal>
            </>
          ) : null}
        </>
      )}
    </Container>
  );
};

export default RoomsList;
