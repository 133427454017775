import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Container } from "./styles";

import api from "../../services/api";

import CircularProgress from "@material-ui/core/CircularProgress";

import * as loginActions from "../../store/modules/login/actions";
import * as userRoomsActions from "../../store/modules/userRooms/actions";

const SectionTopBar = (props) => {
  const [logoutModal, setLogoutModal] = useState(false);
  const backgroundColor = useSelector(
    (state) => state.contractReducer.contract.config.backgroundColor
  );
  const fontColor = useSelector(
    (state) => state.contractReducer.contract.config.fontColor
  );
  const logoPath = useSelector(
    (state) => state.contractReducer.contract.config.logoPath
  );
  const brandColor = useSelector(
    (state) => state.contractReducer.contract.config.brandColor
  );

  const User = useSelector((state) => state.loginReducer.user);
  const userId = useSelector((state) => state.loginReducer.user.id);
  let TestUser = useSelector((state) => state.loginReducer.user.test);
  const TestUserDate = useSelector((state) => state.loginReducer.user.testDate);
  const UserType = useSelector((state) => state.loginReducer.user.type);

  const [ready, setReady] = useState(false);
  let [dateLast, setDateLast] = useState(0);
  let [testOver, setTestOver] = useState(false);
  let [modalLoad, setModalLoad] = useState(false);

  const dispatch = useDispatch();

  const Test = (q) => {
    let p1 = JSON.parse(localStorage.getItem("persist:appcria"));
    let p2 = JSON.parse(p1.loginReducer);

    p2.user.test = q;

    let date = new Date();
    date.setDate(date.getDate() + 7);

    p2.user.testDate = date;

    p1.loginReducer = JSON.stringify(p2);
    localStorage.setItem("persist:appcria", JSON.stringify(p1));

    if (q) {
      api
        .put(`user/${userId}`, { dateExpirationTest: date })
        .then(() => {})
        .catch((error) => console.log(error));

      setModalLoad(true);
      setTimeout(function () {
        window.location.reload();
      }, 3000);
    }
  };

  if (!ready) {
    let date1 = new Date(TestUserDate);
    let date2 = new Date();
    dateLast = date1.getDate() - date2.getDate();
    setDateLast(date1.getDate() - date2.getDate());

    if (date1 < date2) {
      setTestOver(true);
      Test(false);
      TestUser = false;
    } else {
      setTestOver(false);
    }
    setReady(true);
  }

  const handleLogoutClick = () => {
    dispatch(loginActions.loginFailure());
    dispatch(userRoomsActions.returnUserRoomsInitialState());
    localStorage.removeItem("@CRIA_TOKEN");
    sessionStorage.removeItem("@CRIA_TOKEN");
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  return (
    <Container
      brandColor={brandColor}
      backgroundColor={backgroundColor}
      fontColor={fontColor}
    >
      {modalLoad && (
        <>
          <div className="fundo"></div>
          <div className="modalTest">
            <h1>Aguarde...</h1>
            <CircularProgress />
          </div>
        </>
      )}

      {props.onClickBack ? (
        <i className="fas fa-arrow-left" onClick={props.onClickBack}></i>
      ) : (
        <p className="p-greeting">
          Bem vindo(a) <strong>{User.name}</strong>
          {UserType === "lead" && (
            <>
              {TestUser ? (
                <font className="testWarning">
                  Faltam {dateLast} dias de teste!
                </font>
              ) : testOver ? (
                <font className="testOver">Seu teste acabou!</font>
              ) : (
                <button onClick={() => Test(true)}>
                  Testar versão completa
                </button>
              )}
            </>
          )}
        </p>
      )}

      <img src={logoPath} alt="Logotipo"></img>

      <button className="logoutBtn" onClick={() => setLogoutModal(true)}>
        <i class="fas fa-sign-out-alt"></i> Sair
      </button>

      {logoutModal && (
        <div
          className="logoutModal"
          onClick={(e) => {
            e.target.classList.contains("logoutModal") && setLogoutModal(false);
          }}
        >
          <div className="contentLogout">
            <div>
              <p>Deseja se desconectar?</p>
            </div>
            <div className="buttons">
              <button onClick={() => setLogoutModal(false)}>Cancelar</button>
              <button onClick={handleLogoutClick}>Sair</button>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default SectionTopBar;
