import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";

// Styles
import { Container, Button, ButtonGroup } from "./styles";
import InputMask from "react-input-mask";
import FormControl from "@material-ui/core/FormControl";

import Dropdown from "react-dropdown";
import GenericModal from "../../components/GenericModal";
import ModalBackground from "../../components/ModalBackground";
import "react-dropdown/style.css";
import RegisterData from "./RegisterData";
import Users from "./Users";
import Rooms from "./Rooms";
import Materials from "./Materials";

import * as contractActions from "../../store/modules/contract/actions";
import api from "../../services/api";

import { contractContext } from "../../Contexts/contractContext";

import Loader from "../../components/Loader";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const Contracts = (props) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.loginReducer.user);

  const brandContrastColor = useSelector(
    (state) => state.contractReducer.contract.config.brandContrastColor
  );
  const brandColor = useSelector(
    (state) => state.contractReducer.contract.config.brandColor
  );
  const backgroundColor = useSelector(
    (state) => state.contractReducer.contract.config.backgroundColor
  );
  const fontColor = useSelector(
    (state) => state.contractReducer.contract.config.fontColor
  );

  const contracts = useSelector(
    (state) => state.contractReducer.contracts.data
  );
  const isAllContractsLoading = useSelector(
    (state) => state.contractReducer.contracts.isLoading
  );

  const loggedContract = useSelector((state) => state.contractReducer.contract);
  //const isLoggedContractLoading = useSelector(state => state.contractReducer.contract.isLoading)

  const [sessaoPrincipal, setSessaoPrincipal] = useState(props.sessao);
  const [contractList, setContractList] = useState([]);

  const [currentContract, setCurrentContract] = useState();

  //const [initicialContract, setIniticialContract] = useState(useSelector(state => state.contractReducer.contract.contractData));
  //const [initicialContract, setIniticialContract] = useState();

  const [logochanged, setLogochanged] = useState(false);

  const [contractRooms, setContractRooms] = useState([]);

  const [isEditing, setIsEditing] = useState(true);

  const [displaySaveMsg, setDisplaySaveMsg] = useState(false);
  const [saveMsg, setSaveMsg] = useState("");
  const [autoCompleteInput, setAutoCompleteInput] = useState("");

  const [newMaterialList, setNewMaterialList] = useState([]);

  const [displayMsgSaveBeforeContinue, setDisplayMsgSaveBeforeContinue] =
    useState(false);

  const [isLoadingContractData, setIsLoadingContractData] = useState(true);

  //let [inputValue, setInputValue] = useState();

  useEffect(() => {
    //loadContractData(initicialContractId)

    if (!loggedContract.isLoading) {
      setCurrentContract(loggedContract.data);
      setIsLoadingContractData(false);
      setAutoCompleteInput(loggedContract.data.name);
    }
  }, [loggedContract]);

  const sortContractList = (pContracts) => {
    const listHelper = pContracts
      .map((c) => {
        return {
          value: c._id,
          label: c.name,
        };
      })
      .sort(function (a, b) {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }

        return 0;
      });
    return listHelper;
  };

  useEffect(() => {
    if (!isAllContractsLoading) {
      setContractList(sortContractList(contracts));
    }
  }, [contracts]);

  useEffect(() => {
    isAllContractsLoading && dispatch(contractActions.getAllContracts(null));
  }, [isAllContractsLoading]);

  const handleMainSessionClick = (session) => {
    if (!isEditing && currentContract) {
      setDisplayMsgSaveBeforeContinue(true);
      return;
    }
    setSessaoPrincipal(session);
  };

  const handleContractChange = (event) => {
    setIsEditing(true);
    if (event.target.value) loadContractData(event.target.value);
  };

  const loadContractData = (contractId) => {
    setIsLoadingContractData(true);
    setSessaoPrincipal("registerData");
    api
      .get(`contract/${contractId}`)
      .then(({ data }) => {
        setCurrentContract(data.contract);
        setContractRooms(data.contract.rooms);
      })
      .finally(() => setIsLoadingContractData(false));
  };

  const blobToBase64 = (blobUrl) => {
    return new Promise((resolve, _) => {
      var xhr = new XMLHttpRequest();
      xhr.responseType = "blob";

      xhr.onload = function () {
        var recoveredBlob = xhr.response;

        var reader = new FileReader();

        reader.onloadend = () => resolve(reader.result);

        reader.readAsDataURL(recoveredBlob);
      };

      xhr.open("GET", blobUrl);
      xhr.send();
    });
  };

  const uploadLogo = async (contractId, logoPath) => {
    let logoHelper = await blobToBase64(logoPath);

    logoHelper = logoHelper
      .replace("data:image/webpbase64,", "")
      .replace("data:image/webp;base64,", "")
      .replace("data:image/pngbase64,", "")
      .replace("data:image/png;base64,", "")
      .replace("data:image/jpgbase64,", "")
      .replace("data:image/jpg;base64,", "");

    return api.post("order/uploadFile", {
      key: `assets/img/logos/${contractId}-${Date.now()}.png`,
      data: logoHelper,
    });
  };

  const checkMaterialsAddedAndRemoved = () => {
    const contractMaterials = currentContract.materials.map((m) => m.material);

    const added = newMaterialList.filter((m) => !contractMaterials.includes(m));

    const removed = contractMaterials.filter(
      (m) => !newMaterialList.includes(m)
    );

    return {
      added: added,
      removed: removed,
    };
  };

  const handleNewMaterialList = async () => {
    const materialHelpers = checkMaterialsAddedAndRemoved();

    const QTD_REQUEST = 1000;

    if (materialHelpers.added.length > 0) {
      let addList = [];

      const price = "0,00";
      const inStock = 1.0;

      materialHelpers.added.forEach((mat, index) => {
        addList.push({
          //material: mat, price: price, inStock: inStock
          material: mat,
        });
      });

      const loops = Math.ceil(addList.length / QTD_REQUEST);

      for (let index = 0; index < loops; index++) {
        const start = index * QTD_REQUEST;
        const end = index * QTD_REQUEST + (QTD_REQUEST - 1);
        const respAdd = await api.post(
          `/contract/${currentContract._id}/addMaterials`,
          addList.slice(start, end)
        );
      }

      //const respAdd = await api.post(`/contract/${currentContract._id}/addMaterials`, addList)

      addList = [];
    }

    if (materialHelpers.removed.length > 0) {
      let removeList = materialHelpers.removed;

      const loops = Math.ceil(removeList.length / QTD_REQUEST);

      for (let index = 0; index < loops; index++) {
        const start = index * QTD_REQUEST;
        const end = index * QTD_REQUEST + (QTD_REQUEST - 1);
        const respRemove = await api.post(
          `/contract/${currentContract._id}/removeMaterials`,
          removeList.slice(start, end)
        );
      }

      removeList = [];
    }
  };

  const saveContract = (pContract) => {
    const newContract = {
      ...pContract,
      andress: pContract.andress,
      association: pContract.association,
      cpfcnpj: pContract.cpfcnpj,
      email: pContract.email,
      inactivationDate: pContract.inactivationDate,
      inactivationReason: pContract.inactivationReason,
      nBrands: pContract.nBrands,
      name: pContract.name,
      phone: pContract.phone,
      plan: pContract.plan,
      responsible: pContract.responsible,
      rooms: pContract.rooms,
      status: pContract.status,
      whiteLabel: pContract.whiteLabel,
    };

    delete newContract.materials;

    if (isEditing) return api.put(`contract/${pContract._id}`, newContract);

    return api.post("contract/", newContract);
  };

  const handleSaveButton = async () => {
    if (!currentContract.name) {
      setSaveMsg("O Nome/Razão Social deve ser preenchido");
      setDisplaySaveMsg(true);
      return;
    }

    if (logochanged) {
      const uploadResp = await uploadLogo(
        `${currentContract.name}-logo`,
        currentContract.whiteLabel.logoPath
      );

      setLogochanged(false);

      //não consegui via setState
      currentContract.whiteLabel.logoPath = uploadResp.data.data.imageSrc;

      if (!uploadResp.data.success) {
        setSaveMsg(
          `Erro ao fazer upload da logo. Mensagem de erro: ${uploadResp.data.status}`
        );
        setDisplaySaveMsg(true);
        return;
      }
    }

    const saveResp = await saveContract(currentContract);

    if (!saveResp.data.success) {
      setSaveMsg(
        `Erro ao salvar o contrato. Mensagem de erro: ${saveResp.data.status}`
      );
      return;
    }

    !isEditing && dispatch(contractActions.getAllContracts(null));

    //setCurrentContract({ _id: saveResp.data.id })
    const contractId = saveResp.data.id;

    setIsEditing(true);

    newMaterialList.length > 0 && (await handleNewMaterialList());

    loggedContract.contractId === contractId
      ? dispatch(contractActions.getContract(user.id))
      : loadContractData(contractId);

    setNewMaterialList([]);

    setSaveMsg("Contrato salvo com sucesso!");

    setTimeout(() => {
      setDisplaySaveMsg(false);
    }, 2500);

    setDisplaySaveMsg(true);
  };

  const handleNewContract = () => {
    setIsEditing(false);
    //setContract({})
    setCurrentContract({
      status: "active",
    });
    setSessaoPrincipal("registerData");
  };
  return (
    <Container
      backgroundColor={backgroundColor}
      fontColor={fontColor}
      brandColor={brandColor}
      display={isLoadingContractData ? "none" : "flex"}
    >
      <h1>Dados do Contrato</h1>

      {user.type === "admin" ? (
        <FormControl className="contrato-selector">
          <label>Contratos</label>
          <div className="dropAndButton">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={currentContract ? currentContract._id : ""}
              label="Age"
              fullWidth
              onChange={handleContractChange}
            >
              {contractList.map((contract, index) => {
                return (
                  <MenuItem key={index} value={contract.value}>
                    {contract.label}
                  </MenuItem>
                );
              })}
            </Select>
            {/* <Dropdown className="dropContratos" options={contractList} onChange={handleContractChange} value={currentContract ? currentContract.name : ""} /> */}
            <button className="btn-novo-contrato" onClick={handleNewContract}>
              Novo Contrato
            </button>
          </div>
        </FormControl>
      ) : (
        ""
      )}

      <div className="botoes-sessao-princial">
        <button
          className={`botao-sessao-princial ${
            sessaoPrincipal === "registerData"
              ? "sessao-principal-selecionada"
              : ""
          }`}
          onClick={() => handleMainSessionClick("registerData")}
        >
          Dados Cadastrais
        </button>
        <button
          className={`botao-sessao-princial ${
            sessaoPrincipal === "users" ? "sessao-principal-selecionada" : ""
          }`}
          onClick={() => handleMainSessionClick("users")}
        >
          Usuários
        </button>
        {user.type === "admin" ? (
          <>
            <button
              className={`botao-sessao-princial ${
                sessaoPrincipal === "rooms"
                  ? "sessao-principal-selecionada"
                  : ""
              }`}
              onClick={() => handleMainSessionClick("rooms")}
            >
              Ambientes
            </button>
            <button
              className={`botao-sessao-princial ${
                sessaoPrincipal === "materials"
                  ? "sessao-principal-selecionada"
                  : ""
              }`}
              onClick={() => handleMainSessionClick("materials")}
            >
              Materiais
            </button>
          </>
        ) : (
          ""
        )}
      </div>

      <div className="contrato">
        {isLoadingContractData ? (
          <Loader />
        ) : (
          <>
            <contractContext.Provider
              value={{
                currentContract,
                setCurrentContract,
                logochanged,
                setLogochanged,
                contractRooms,
                setContractRooms,
                setNewMaterialList,
              }}
            >
              {(sessaoPrincipal === "registerData" && <RegisterData />) ||
                (sessaoPrincipal === "users" && <Users />) ||
                (sessaoPrincipal === "rooms" && <Rooms />) ||
                (sessaoPrincipal === "materials" && (
                  <Materials handleMainSessionClick={handleMainSessionClick} />
                ))}
            </contractContext.Provider>
          </>
        )}
      </div>

      {displaySaveMsg && (
        <ModalBackground
          onClick={() => setDisplaySaveMsg(false)}
        ></ModalBackground>
      )}
      <GenericModal
        display={displaySaveMsg}
        onCloseClick={() => setDisplaySaveMsg(false)}
        arrowYPosition={"28vh"}
        arrowXPosition={"6vw"}
        fontColor={fontColor}
        mainContent={
          <>
            <h3>{"Salvo"}</h3>
            <p></p>
            <p>{"Seu contrato foi salvo com sucesso!"}</p>
          </>
        }
      ></GenericModal>

      {displayMsgSaveBeforeContinue && (
        <ModalBackground
          onClick={() => setDisplayMsgSaveBeforeContinue(false)}
        ></ModalBackground>
      )}
      <GenericModal
        display={displayMsgSaveBeforeContinue}
        onCloseClick={() => setDisplayMsgSaveBeforeContinue(false)}
        arrowYPosition={"28vh"}
        arrowXPosition={"6vw"}
        fontColor={fontColor}
        mainContent={
          <>
            <h3>{"Novo Contrato"}</h3>
            <p></p>
            <p>{"Salve o novo contrato para continuar o cadastro!"}</p>
          </>
        }
      ></GenericModal>

      <button className="save-btn" onClick={handleSaveButton}>
        SALVAR CONTRATO
      </button>
    </Container>
  );
};

export default Contracts;
