import styled from "styled-components";

export const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
  font-family: "Montserrat", sans-serif;
  margin-top: 38px;
  margin-bottom: 40px;
  height: calc(${(props) => props.innerHeight}px - 78px);
  overflow-y: auto;
  overflow-x: hidden;
  align-content: start;

  @media only screen and (min-width: 1024px) {
    h3 {
      font-size: 18px !important;
      margin-top: 32px !important;
      margin-bottom: 32px !important;
    }

    div.active {
      z-index: 5 !important;
    }

    .environment-options {
      margin: auto 4vw !important;
    }

  }

  @media only screen and (min-height: 1750px) and (max-width: 1080px) {
    h3 {
      font-size: 22px !important;
      margin-top: 60px !important;
      margin-bottom: 32px !important;
    }

    .environment-options ul {
      width: 100%!important;
      flex-wrap: wrap;
      column-gap: 16px;
      row-gap: 16px;
      justify-content: center;
    }

    .environment-options ul li {
      width: auto!important;
    }

    .environment-options {
      margin: 8px 16px!important;
      gap: 8px;
    }

    button.button-next {
      display: none!important;
    }

  }

  @media only screen and (min-width: 1200px) {
    h3 {
      font-size: 24px !important;
      margin-top: 32px !important;
      margin-bottom: 32px !important;
    }
  }

  h3 {
    font-size: 13px;
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: center;
    font-weight: 700;
    color: ${(props) => props.fontColor};
    width: 100%;
  }

  div.environment-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
  }

  .active {
    z-index: 4;
  }

  .slider {
    transition: transform 0.3s linear;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  button.button-back,
  button.button-next {
    position: absolute;
    top: 50%;
    margin-top: -20px;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    float: right;
    border: none;
    font-size: 12px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: ${(props) => props.brandColor};
    background-color: ${(props) => props.backgroundColor};
  }

  button.button-back:disabled,
  button.button-next:disabled {
    display: none;
  }

  button.button-back:hover,
  button.button-next:hover {
    opacity: 0.8;
  }

  button.button-back {
    left: 2vw;
  }

  button.button-next {
    right: 2vw;
  }

  .notAuthorizedClass div div:first-child:after {
    color: black;
    font-family: "Font Awesome 5 Free";
    content: "\f023";
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100%;
    top: 0;
    width: 100%;
    font-weight: 600;
    border-radius: 8px;
    background: #cbcbcb94;
    font-size: 40px;
    color: #00000052;
  }

  .notAuthorizedClass {
    // pointer-events:none;
  }

  .buttonLock {
    margin-top: 35px;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 9%;
  }

  .buttonLock button {
    font-weight: 500;
    color: white;
    background-color: #00000066;
    padding: 5px 15px;
    border: 0;
    border-radius: 20px;
    color: #ffffffd1;
    cursor: pointer;
  }

  .buttonLock button:hover {
    background-color: black;
    transition: 0.2s;
  }

  .loadingClass div {
    width: 100px !important;
    height: 100px !important;
    color: ${(props) => props.brandColor} !important;
  }

  .modalPlanoClass h4 {
    color: ${(props) => props.brandColor};
  }

  .notAuthClass > div:first-child:after {
    display: flex;
    font-weight: 600;
    background: #a9a9a96e;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 8px;
    font-family: "Font Awesome 5 Free";
    content: "\f023";
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: #00000052;
  }

  .buttonLock2 {
    width: 100%;
    text-align: center;
    margin-top: -34%;
    position: relative;
  }

  .buttonLock2 button {
    font-weight: 500;
    color: white;
    background-color: #00000066;
    padding: 5px 15px;
    border: 0;
    border-radius: 20px;
    color: #ffffffd1;
    cursor: pointer;
  }

  .buttonLock2 button:hover {
    background-color: black;
    transition: 0.2s;
  }

  .modalClasse > div:first-child {
    left: 0;
    text-align: center;
    width: 30%;
    margin-left: 35%;
    z-index: 4;
  }

  .modalClasse a {
    color: ${(props) => props.brandColor} !important;
  }

  .modalClasse .fundo {
    z-index: 3;
    background: #00000040;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    cursor: pointer;
  }
`;
