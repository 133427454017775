import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
//import { Offline, Online } from "react-detect-offline";
import { useSelector } from 'react-redux'

// Styles
// Components
import SectionTopBar from '../../components/SectionTopBar'
import NavigationBar from '../../components/NavigationBar'
import AllList from './components/AllList/index'


const Alls = (props) => {
  const history = useHistory()

  const [isConnectionUnstable, setIsConnectionUnstable] = useState(false)

  useEffect(() => {
    if (navigator.connection) {
      const handleConnectionChange = () => navigator.connection.downlink < 0.45 ? setIsConnectionUnstable(true) : setIsConnectionUnstable(false)
      handleConnectionChange()
      navigator.connection.onchange = handleConnectionChange
    }
  }, []);

  return (
      <>
          <SectionTopBar
              onClickBack={() => props.history.push("/")}
          ></SectionTopBar>
          <AllList history={history}></AllList>
          <NavigationBar viewIndex={1}></NavigationBar>
      </>
  );
}

export default Alls