import styled from "styled-components";

export const Container = styled.div`
  align-items: center;
  display: ${(props) => (props.modal ? "flex" : "none")};
  justify-content: start;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;

  @media only screen and (min-width: 1024px) {
    width: 380px !important;

    nav.navbar,
    div.filter-nav-bar {
      width: 380px !important;
    }

    div.general-filter,
    div.brands-filter,
    div.size-filter {
      width: 346px !important;
    }
  }

  nav.navbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 38px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
    width: 100vw;
  }

  nav.navbar h1 {
    font-size: 14px;
    font-weight: 500;
    color: ${(props) => props.fontColor};
    margin-left: 16px;
  }

  nav.navbar i.fa-arrow-left {
    color: ${(props) => props.fontColor};
    font-size: 14px;
    margin-left: 16px;
    cursor: pointer;
  }

  nav.navbar i.fa-arrow-left:hover {
    opacity: 0.8;
  }

  div.filter-nav-bar {
    display: flex;
    position: relative;
    flex-wrap: no-wrap;
    height: 32px;
    width: 100vw;
    align-items: center;
    background-color: transparent;
  }

  div.filter-nav-bar div {
    flex: 1;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    color: ${(props) => props.fontColor};
    font-size: 21px;
    cursor: pointer;
  }

  div.filter-nav-bar div h3 {
    font-size: 12px;
    color: ${(props) => props.fontColor};
  }

  div.filter-nav-bar div.selected {
    border-bottom: 2px solid ${(props) => props.brandColor};
    color: ${(props) => props.brandColor};
  }

  .section__title {
    margin: 16px 0 8px 8px;
    font-size: 16px;
    color: ${(props) => props.fontColor};
  }

  div.general-filter,
  div.brands-filter,
  div.size-filter {
    width: calc(100vw - 24px);
  }

  button.filter-tag {
    border: none;
    height: 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 12px;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    color: #222;
    background: #fff;
    padding-left: 12px;
    padding-right: 12px;
    margin: 4px 8px 4px 0;
    box-shadow: 0 0.2px 4px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
  }

  button.selected {
    border: 1px solid ${(props) => props.brandColor};
    color: ${(props) => props.brandColor};
  }

  @media (min-width: 812px) {
    .section {
      flex-direction: row;
      align-self: center;
      align-items: baseline;
      justify-content: space-around;
      width: 100%;
      height: 100vh;
    }

    .section__brand {
      min-height: 65%;
    }
    .section__format {
      min-height: 65%;
    }
  }

  @media (min-width: 320px) {
    .section {
      height: 70vh;
    }
    .section__brand {
      min-height: 35%;
    }
    .section__format {
      min-height: 25%;
    }
  }

  .modal__button {
    background: none;
    border: 0;
    width: 100%;
    color: #2d3436;
    font-size: 11px;
  }

  .modal__header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin: 16px 0 16px 0;
  }

  .modal__header__title {
    font-size: 18px;
  }

  .modal__header__button {
    background: none;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .section__color {
    height: 10%;
    margin-top: 12px;
    width: 90%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .section__brand__title {
    font-size: 16px;
    color: #2d3436;
  }

  .section__content {
    display: flex;
  }

  .section__brand {
    height: 35%;
    margin-top: 36px;
    width: 90%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .section__list {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    width: 100%;
    max-height: 300px;
    padding: 4px;
  }

  .section__list__item {
    padding: 8px 8px 8px 0;

    input {
      margin-right: 8px;
    }
  }

  .section__format {
    height: 45%;
    margin-top: 36px;
    width: 90%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .section__format__title {
    font-size: 16px;
    color: #2d3436;
  }

  .section__format__list {
    overflow: scroll;
    width: 100%;
    max-height: 200px;
  }

  @media only screen and (min-height: 1750px) and (max-width: 1080px) {
    width: 100%!important;   

    nav.navbar {
      width: 100%!important;   
    } 

    div.filter-nav-bar {
      height: 40px !important;
    }

    div.general-filter,
    div.brands-filter,
    div.size-filter,
    div.filter-nav-bar,
    div.section__list {
      width: 560px !important;      
    }

    div {
      width: 560px !important; 
    }
  }

`;

export const Cell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 16px auto 16px auto;

  button {
    display: flex;
    position: relative;
    width: 138px;
    height: 32px;
    border-radius: 16px;
    border: none;
    font-size: 12px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    justify-content: center;
    align-items: center;
    margin: auto 4px auto 4px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: ${(props) => props.backgroundColor};
    background-color: ${(props) => props.brandColor};
  }

  button.replace-button {
    color: ${(props) => props.fontColor};
    background-color: ${(props) => props.backgroundColor};
  }

  button.new-environment-button {
    color: ${(props) => props.backgroundColor};
    background-color: ${(props) => props.brandColor};
  }

  button:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;
