import React, { useContext, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

// Styles
import { Container } from "./styles";
import InputMask from "react-input-mask";
import FormControl from "@material-ui/core/FormControl";
import Switch from "react-switch";
import api from "../../../../services/api";

import { contractContext } from "../../../../Contexts/contractContext";

import { DebounceInput } from "react-debounce-input";

import Dropdown from "react-dropdown";

const UserData = (props) => {
  const brandColor = useSelector(
    (state) => state.contractReducer.contract.config.brandColor
  );
  const backgroundColor = useSelector(
    (state) => state.contractReducer.contract.config.backgroundColor
  );
  const fontColor = useSelector(
    (state) => state.contractReducer.contract.config.fontColor
  );

  const [selectedUser, setSelectedUser] = useState();
  const { currentContract, setCurrentContract } = useContext(contractContext);

  const [name, setName] = useState("");
  const [status, setStatus] = useState("active");
  const [editing, setEditing] = useState("create");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [login, setLogin] = useState("");
  const [type, setType] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");

  const [errorValidateMessages, setErrorValidateMessages] = useState([]);
  const [displaySaveMsg, setDisplaySaveMsg] = useState(false);

  const clearUserData = () => {
    setName("");
    setStatus("active");
    setEmail("");
    setPhone("");
    setType("professional");
    setLogin("");
    setPassword("");
    setEditing("create");
    setPasswordCheck("");
  };

  useEffect(() => {
    clearUserData();

    if (selectedUser) {
      setName(selectedUser.name);
      setStatus(selectedUser.status);
      setEmail(selectedUser.email);
      setPhone(selectedUser.phone);
      setLogin(selectedUser.login);
      setType(selectedUser.type);
      //setPassword(selectedUser.password)
      setEditing(selectedUser.editing);
    }
  }, [selectedUser]);

  useEffect(() => {
    setSelectedUser(props.selectedUser);
  }, [props.selectedUser]);

  const validateLogin = async () => {
    if (
      (login && !selectedUser) ||
      (selectedUser && login != selectedUser.login)
    ) {
      try {
        const respLogin = await api.get("user/login/" + login);

        return !respLogin.data.success;
      } catch (error) {
        return true;
      }
    }
    return true;
  };

  const validateEmailSintaxe = () => {
    const mailformat = /^\w*(\.\w*)?@\w*\.[a-z]+(\.[a-z]+)?$/;
    const isValid = email.match(mailformat);
    return isValid;
  };

  const validateEmail = async () => {
    if (
      (email && !selectedUser) ||
      (selectedUser && selectedUser.email != email)
    ) {
      try {
        const respEmail = await api.get("user/email/" + email);

        return !respEmail.data.success;
      } catch (error) {
        return true;
      }
    }
    return true;
  };

  const handleStatusChange = (value) => {
    setStatus(value ? "active" : "inactive");
  };

  const validateForm = async () => {
    const msgErrorHelper = [];

    if (!name) msgErrorHelper.push("Nome deve ser preechido");

    if (email) {
      if (!validateEmailSintaxe()) msgErrorHelper.push("E-mail incorreto");
    }

    const isValidEmail = await validateEmail();
    if (!isValidEmail) msgErrorHelper.push("E-mail já está sendo utilizado");

    if (!type) msgErrorHelper.push("Tipo do usuário deve ser preenchido");
    /*
    if (phone.length < 12)
      msgErrorHelper.push('Fone incorreto')      
    */

    if (!login) msgErrorHelper.push("Login deve ser preenchido");

    if (login.includes("@"))
      msgErrorHelper.push("Login não pode ser um e-mail");

    const isValidLogin = await validateLogin();
    if (!isValidLogin) msgErrorHelper.push("Login já está sendo utilizado");

    if (password != passwordCheck)
      msgErrorHelper.push("Senha e Confirmação não coincidem");

    if (password.length < 3 && editing == "create")
      msgErrorHelper.push("Senha deve ter ao menos 3 caracteres");

    setErrorValidateMessages(msgErrorHelper);

    return msgErrorHelper.length <= 0;
  };

  const handleSaveUserButton = async (value) => {
    const formIsValid = await validateForm();

    if (!formIsValid) return;

    let user = {
      name: name,
      status: status,
      email: email,
      phone: phone,
      type: type,
      login: login,
    };

    if (password) user.password = password;

    let resp = "";

    if (editing == "update") {
      resp = await api.put(`user/${selectedUser.id}`, user);
    } else {
      if (!user.type) user.type = "professional";

      resp = await api.post("signup/", user);

      if (resp && resp.data.success) {
        const respAddUser = await api.post(
          `contract/${currentContract._id}/user`,
          { user: resp.data.id }
        );
      }
    }

    if (resp.data.success) {
      setTimeout(() => {
        setDisplaySaveMsg(false);
      }, 2000);

      api.get(`contract/${currentContract._id}`).then(({ data }) => {
        setCurrentContract(data.contract);
      });

      setDisplaySaveMsg(true);
      clearUserData();
      props.onCloseClick();
    }
  };

  const typeOptions = [
    { value: "professional", label: "Vendedor" },
    { value: "lead", label: "Lead" },
    { value: "manager", label: "Gerente" },
  ];

  return (
    <Container
      backgroundColor={backgroundColor}
      fontColor={fontColor}
      brandColor={brandColor}
    >
      <div className="form-user">
        <FormControl className="nome-item">
          <label>Nome</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </FormControl>

        <FormControl className="user-data-items-left">
          <label>E-mail</label>
          {/* <input type="text" value={email} onChange={e => setEmail(e.target.value)} /> */}
          <DebounceInput
            minLength={2}
            debounceTimeout={500}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </FormControl>

        <FormControl className="status user-data-items-left">
          <label>Ativo</label>
          <Switch onChange={handleStatusChange} checked={status == "active"} />
        </FormControl>

        <br />
        <br />

        <FormControl className="">
          <label>Fone</label>
          <InputMask
            mask="5599999999999"
            type="tel"
            value={phone}
            placeholder="(xx) 00000-0000"
            onChange={(e) => setPhone(e.target.value)}
          />
        </FormControl>

        <FormControl className="user-data-items-left">
          <label>Tipo</label>
          <Dropdown
            className="dropTipos"
            options={typeOptions}
            onChange={(e) => setType(e.value)}
            value={type}
            placeholder="Tipo"
          />
        </FormControl>

        <br />
        <br />

        <FormControl className="">
          <label>Login</label>
          <input
            type="text"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
          />
          {/* <DebounceInput minLength={2} debounceTimeout={300} onChange={(e) => setLogin(e.target.value)} value={login} /> */}
        </FormControl>

        <FormControl className="user-data-items-left">
          <label>Senha</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormControl>

        <FormControl className="user-data-items-left">
          <label>Confirmação Senha</label>
          <input
            type="password"
            value={passwordCheck}
            onChange={(e) => setPasswordCheck(e.target.value)}
          />
        </FormControl>
      </div>
      <div className="error-msg">
        {errorValidateMessages.map((msg, i) => {
          return (
            <label key={i} className="error-validate">
              {msg}
            </label>
          );
        })}
      </div>
      <div className="footer-user">
        <label
          className="save-msg-label"
          style={{ display: displaySaveMsg ? "block" : "none" }}
        >
          Usuário salvo
        </label>
        <button className="save-user-btn" onClick={handleSaveUserButton}>
          Salvar
        </button>
        <button
          className="cancel-user-btn"
          onClick={() => props.onCloseClick()}
        >
          Cancelar
        </button>
      </div>
    </Container>
  );
};

export default UserData;
