import styled from "styled-components";

export const StylesList = styled.div`
  width: 94vw;
  margin: 12px 3vw auto 3vw;
  padding-bottom: 0;
  height: ${(props) => props.innerHeight - 160}px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  @media only screen and (min-width: 1024px) {
    width: 292px !important;
    margin: 0 0 auto 8px !important;
    height: calc(100vh - 92px);

    div.divider {
      width: 284px !important;
    }

    li,
    .slide {
      margin: 0 6px 0.2rem 6px !important;
    }

    li img {
      width: calc(266px / 1.5) !important;
    }

    .active {
      z-index: 5 !important;
    }
  }

  div.divider {
    position: relative;
    flex-wrap: wrap;
    width: 94vw;
    margin-top: 8px;
  }

  div nav {
    display: flex;
    align-items: center;
  }

  div h2 {
    color: ${(props) => props.fontColor};
    font-size: 15px;
    margin: 0 0 10px 8px;
    font-weight: 700;
    text-align: start;
    width: 100%;
  }

  ul,
  .slider {
    display: flex;
    height: auto;
    overflow: auto;
    margin-bottom: 32px;
    padding-right: 20px;
    transition: transform 0.3s linear;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  li,
  .slide {
    margin: 0 2vw 0.2rem 2vw;
    border-radius: 8px;
    height: auto;
    cursor: pointer;
  }

  li:hover {
    opacity: 0.95;
  }

  .active {
    z-index: 4;
  }

  li img {
    width: calc(88vw / 1.5);
    height: auto;
    border-radius: 8px;
  }

  button.button-back,
  button.button-next {
    position: absolute;
    top: 50%;
    margin-top: -14px;
    height: 28px;
    width: 28px;
    border-radius: 14px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    float: right;
    border: none;
    font-size: 12px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: ${(props) => props.brandColor};
    background-color: ${(props) => props.backgroundColor};
  }

  button.button-back:disabled,
  button.button-next:disabled {
    display: none;
  }

  button.button-back:hover,
  button.button-next:hover {
    opacity: 0.8;
  }

  button.button-back {
    left: 0;
  }

  button.button-next {
    right: 0;
  }
`;

export const Warn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 14px;
    color: #141414;
    margin-top: 32px;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
  }
`;
