import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  width: 80vw;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 0.2px 4px rgba(0, 0, 0, 0.20);
  display: ${props => props.display};
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  opacity: 1;
  z-index: 4;  
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 1px);
  margin: 0px

  @media only screen and (min-width: 768px) {
    width: 50vw;
    div.content h3,
    div.content p,
    div.content small.alert,
    div.content input[type=text] {
      width: 348px !important;
    }
  }

  .loadingClass div {
    width: 20px !important;
    height: 20px !important;
    color: ${props => props.brandColor} !important;
  }
  
  h3 {
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: 700;
    //text-align: center;
    color: ${props => props.brandColor};
    width: calc(88vw - 32px);
    flex: 1;
  }

  p {
    margin-bottom: 12px;
    font-size: 13px;
    color: ${props => props.fontColor};
    width: 70% !important;
  }

  @media only screen and (min-width: 768px){
    input#inputbtn{
      width: 100% !important;
    }
  }

  @media only screen and (max-width: 767px){
    input#inputbtn{
      width: 81%;
    }
  }

  @media only screen and (max-width: 356px){
    input#inputbtn{
    width: 100%;
    }
  }

  input#inputbtn{
    width: 81%;
  }

  div.screenshotImg img {
    max-width: 80vw;
    border-radius: 15px;
  }

  i.close {
    position: absolute;
    right: 0px;
    font-size: 20px;
    margin: 8px;
    padding: 6px;
    color: ${props => props.fontColor};
  }

  i.close i:hover {
    opacity: .8;
    cursor: pointer;
    box-shadow: 3px 3px 3px 0px #666;
  }

  div.footer button:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  div.footer {
    display: flex;
    width: 96%;
    justify-content: flex-end;
    position: absolute;
    bottom: 20px;
  }

  div.footer button {
    background-color: #fff;
    box-shadow: 0 8px 12px 0 rgba(0,0,0,0.24),0 12px 45px 0 rgba(0,0,0,0.19);
    border-radius: 35px;
    border: none;
    padding: 12px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.3s;
  }

  div.footer i {
    position: relative;
    font-size: 32px;
  }

  div.footer button.whatsapp {
    margin-right: 10px;
  }

  div.footer button.whatsapp i {
    color: green;
  }

  @media only screen and (max-width: 768px){
    div.footer button {
    padding: 7px 10px;
    }

    div.footer i {
    font-size: 17px;
    }

    div.footer {
    bottom: 8px;
    }

    div.footer button.whatsapp {
    margin-right: 5px;
   }
  }

  @media only screen and (max-width: 768px){
    div.buttons-div{
    width: 80% !important;
    }
  }

  div.buttons-div{
    width: 100%;
  }

  .buttons-div{
    justify-content: flex-end;
  }

  button div.buttonload {
    border: 2px solid ${props => props.backgroundColor};
    border-radius: 50%;
    border-top: 2px solid ${props => props.brandColor};
    width: 20px;
    height: 20px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 2s linear infinite;
  }

  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`