import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: auto !important;
  margin-bottom: 0;
  bottom: 0px;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  max-height: ${(props) => props.maxHeight};
  width: 100vw;
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  z-index: 2;
  font-family: "Montserrat", sans-serif;
  transition: max-height 0.5s ease;
  margin-left: 0px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;


  @media only screen and (min-height: 1750px) and (max-width: 1080px) {

    div.title-bar h3 {
      font-size: 16px!important;
    }  

    div.title-bar {
      height: 48px!important;
    }

  }

  @media only screen and (min-width: 1024px) and (max-height: 1080px) {
    position: relative;
    width: 380px;
    max-height: calc(100vh - 60px);
    height: calc(100vh - 60px);
    bottom: 93vh !important;
    margin-left: 0px;
    transition: max-height 0.5s ease;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 15px;
    animation-name: example;
    animation-duration: 3s;
    animation-fill-mode: forwards;

    @keyframes example {
      from {
        left: -100%;
      }
      to {
        left: 0px;
      }
    }

    div.title-bar {
      display: none !important;
    }

    div.main-content {
      background-color: rgba(0, 0, 0, 0.03);
    }
  }

  @media only screen and (min-width: 1919px) {
    bottom: 95.5vh !important;
  }

  div.title-bar {
    box-shadow: 0 0.2px 4px rgba(0, 0, 0, 0.1);
    height: 38px;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px !important;
    padding-left: 18px !important;
  }

  div.title-bar h3 {
    font-size: 14px;
    text-align: center;
    font-weight: 500;
  }

  div.title-bar i {
    font-size: 18px;
    padding: 8px;
    color: ${(props) => props.fontColor};
  }

  div.title-bar i:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  div.main-content {
    display: initial !important;
    height: ${(props) => props.fitContent};
    font-size: 12px;
    display: flex;
    flex: 1;
  }

  div.main-content div.option {
    font-size: 14px;
    margin-left: 16px;
    height: 32px;
    align-items: center;
    align-content: center;
    line-height: 32px;
  }

  div.main-content div.selected {
    color: ${(props) => props.brandColor};
  }
`;
