import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//import { Offline, Online } from "react-detect-offline";
import api from "../../services/api";
import Modal from "@material-ui/core/Modal";
import InputMask from "react-input-mask";

import * as loginActions from "./../../store/modules/login/actions";
import * as userRoomsActions from "./../../store/modules/userRooms/actions";

// Styles
import {
  OptionsList,
  OfflineDiv,
  Container,
  MainContent,
  Main,
} from "./styles";

// Components
import SectionTopBar from "../../components/SectionTopBar";
import NavigationBar from "../../components/NavigationBar";
import GenericModal from "../../components/GenericModal";
import ModalBackground from "../../components/ModalBackground";

import Contracts from "../../pages/Contracts";

import * as userActions from "../../store/modules/user/actions";

const Profile = () => {
  const history = useHistory();
  const user = useSelector((state) => state.loginReducer.user);

  const [isConnectionUnstable, setIsConnectionUnstable] = useState(false);
  const [displayDeleteAccountModal, setDisplayDeleteAccountModal] =
    useState(false);
  const [displayDeleteAccountConfirm, setDisplayDeleteAccountConfirm] =
    useState(false);
  const [login, setLogin] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [managerAccess, setManagerAccess] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const userId = useSelector((state) => state.loginReducer.user.id);
  const isUserLoading = useSelector((state) => state.userReducer.isLoading);
  const User = useSelector((state) => state.userReducer.user);
  const userType = useSelector((state) => state.loginReducer.user.type);

  const brandContrastColor = useSelector(
    (state) => state.contractReducer.contract.config.brandContrastColor
  );
  const brandColor = useSelector(
    (state) => state.contractReducer.contract.config.brandColor
  );
  const backgroundColor = useSelector(
    (state) => state.contractReducer.contract.config.backgroundColor
  );
  const fontColor = useSelector(
    (state) => state.contractReducer.contract.config.fontColor
  );

  const dispatch = useDispatch();

  const refButton = useRef(null);

  const [currentMenu, setCurrentMenu] = useState("Contratos");

  const [sessao, setSessao] = useState("registerData");

  useEffect(() => {
    function onKeyup(e) {
      if (e.key === "Enter") {
        if (refButton.current) refButton.current.click();
      }
    }
    window.addEventListener("keyup", onKeyup);

    return () => window.removeEventListener("keyup", onKeyup);
  }, []);

  useEffect(() => {
    if (navigator.connection) {
      const handleConnectionChange = () =>
        navigator.connection.downlink < 0.45
          ? setIsConnectionUnstable(true)
          : setIsConnectionUnstable(false);
      handleConnectionChange();
      navigator.connection.onchange = handleConnectionChange;
    }
  }, []);

  useEffect(() => {
    isUserLoading && dispatch(userActions.getUser(userId));

    setLogin(User.login);
    setName(User.name);
    setEmail(User.email);
    setPhone(User.phone);
    setPassword(User.password);
    setManagerAccess(User.type == "manager");
  }, [User, isUserLoading]);

  const handleLogoutClick = () => {
    dispatch(loginActions.loginFailure());
    dispatch(userRoomsActions.returnUserRoomsInitialState());
    localStorage.removeItem("@CRIA_TOKEN");
    sessionStorage.removeItem("@CRIA_TOKEN");
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  const handleDeleteAccount = () => {
    api
      .delete("user/" + userId)
      .then(() => {
        setDisplayDeleteAccountConfirm(true);
        setTimeout(() => {
          setDisplayDeleteAccountConfirm(false);
          setDisplayDeleteAccountModal(false);
          handleLogoutClick();
        }, 4000);
      })
      .catch((err) => console.log(err));
  };

  const apiUpdateEmail = () => {
    api
      .put(`user/${userId}`, {
        email: email,
        login: login,
        name: name,
        phone: phone,
        password: password,
      })
      .then(() => {
        setTimeout(() => {
          setShowLoading(false);
          handleClose();
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }, 3000);
      })
      .catch((err) => console.log(err));
  };

  const apiUpdate = () => {
    api
      .put(`user/${userId}`, {
        name: name,
        phone: phone,
        password: password,
      })
      .then(() => {
        setTimeout(() => {
          setShowLoading(false);
          handleClose();
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }, 3000);
      })
      .catch((err) => console.log(err));
  };

  const apiUpdateLogin = () => {
    api
      .put(`user/${userId}`, {
        login: login,
        email: email,
        name: name,
        phone: phone,
        password: password,
      })
      .then(() => {
        setTimeout(() => {
          setShowLoading(false);
          handleClose();
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }, 3000);
      })
      .catch((err) => console.log(err));
  };

  const handleSave = async () => {
    if (User.email != email) {
      const isEmailValid = validateEmail(email);
      isEmailValid
        ? api
            .get("user/email/" + email)
            .then(() => setErrorMessage("Email já cadastrado."))
            .catch(() => {
              setErrorMessage("");
              apiUpdateEmail();
              setShowLoading(true);
            })
        : setErrorMessage("Email inválido. Tente novamente.");
    } else if (User.login != login) {
      if (login === email || login === User.email) {
        setErrorMessage("Login não pode ser um email.");
      } else {
        api
          .get("user/login/" + login)
          .then(() => setErrorMessage("Login já cadastrado."))
          .catch(() => {
            setErrorMessage("");
            apiUpdateLogin();
            setShowLoading(true);
          });
      }
    } else {
      setErrorMessage("");
      apiUpdate();
      setShowLoading(true);
    }
  };

  const handleEditContract = () => {
    //setOpen(true);
    setCurrentMenu("Contratos");
  };

  const handleEditContractSubmenu = (sessao) => {
    setCurrentMenu("Contratos");
    setSessao(sessao);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function validateEmail(inputText) {
    const mailformat = /^\w*(\.\w*)?@\w*\.[a-z]+(\.[a-z]+)?$/;
    const isValid = inputText.match(mailformat);
    return isValid;
  }

  const formUpdate = () => {
    return (
      <Container
        backgroundColor={backgroundColor}
        fontColor={fontColor}
        brandColor={brandColor}
      >
        <div className="form">
          <label>Login</label>
          <input
            type="text"
            value={login}
            placeholder="Digite seu login"
            onChange={(e) => setLogin(e.target.value)}
            required
          />

          <label>Nome</label>
          <input
            type="text"
            value={name}
            placeholder="Digite seu nome"
            onChange={(e) => setName(e.target.value)}
          />

          <label>Email</label>
          <input
            type="text"
            value={email}
            placeholder="Digite seu email"
            onChange={(e) => setEmail(e.target.value)}
          />

          <label>Telefone</label>
          {/* <InputMask mask="5599999999999" type="tel" value={phone} placeholder="Digite seu telefone" onChange={e => setPhone(e.target.value)} /> */}
          <input
            type="text"
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
          />

          <label>Nova senha</label>
          <input
            type="password"
            placeholder="Digite uma nova senha"
            onChange={(e) => setPassword(e.target.value)}
          />

          <div
            className="content"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {!showLoading && (
              <input
                ref={refButton}
                onClick={() => handleSave()}
                type="submit"
                value="Salvar"
              />
            )}
            {showLoading && (
              <button>
                <div className="c-loader"></div>
              </button>
            )}
            <h5>{errorMessage}</h5>
          </div>
        </div>
      </Container>
    );
  };

  return (
    <>
      <SectionTopBar
        title={"Minha conta"}
        onClickBack={() => history.push("/")}
      ></SectionTopBar>
      <Main className="main">
        <OptionsList
          backgroundColor={backgroundColor}
          fontColor={fontColor}
          brandColor={brandColor}
        >
          {user.type === "admin" || user.type === "manager" ? (
            <>
              <div onClick={handleEditContract} className="option">
                <i className="fas fa-building"></i>
                <h3>Editar Contrato(s)</h3>
              </div>
              {/*<div className="sub-option" onClick={() => handleEditContractSubmenu("users")}>
            <h4>Usuários</h4>
          </div>
          <div className="sub-option" onClick={() => handleEditContractSubmenu("rooms")}>
            <h4>Ambientes</h4>
          </div>
          <div className="sub-option" onClick={() => handleEditContractSubmenu("materials")}>
            <h4>Materiais</h4>
          </div> */}
            </>
          ) : (
            ""
          )}
          <div onClick={handleOpen} className="option">
            <i className="fas fa-cog"></i>
            <h3>Editar meus dados</h3>
          </div>
          <div onClick={handleLogoutClick} className="option">
            <i className="fas fa-sign-out-alt"></i>
            <h3>Desconectar</h3>
          </div>
          <div
            onClick={() => setDisplayDeleteAccountModal(true)}
            className="option"
          >
            <i className="fas fa-trash-alt"></i>
            <h3>Excluir minha conta</h3>
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {formUpdate()}
          </Modal>
        </OptionsList>
        {(userType == "manager" || userType == "admin") && (
          <>
            <MainContent className="main-content">
              {(currentMenu == "Contratos" && sessao == "registerData" && (
                <Contracts sessao="registerData"></Contracts>
              )) ||
                (currentMenu == "Contratos" && sessao == "rooms" && (
                  <Contracts sessao={"rooms"}></Contracts>
                )) ||
                (currentMenu == "Contratos" && sessao == "users" && (
                  <Contracts sessao={"users"}></Contracts>
                ))}
            </MainContent>
          </>
        )}
      </Main>
      {/*<Offline>
        <OfflineDiv backgroundColor={backgroundColor}>
          Você está offline. Verifique sua conexão!
          <i className="fas fa-wifi"></i><i className="fas fa-slash"></i>
        </OfflineDiv>
      </Offline>
      <Online>
        {isConnectionUnstable &&
          <OfflineDiv backgroundColor={backgroundColor}>
            Conexão instável. Verifique sua conexão!
            <i className="fas fa-wifi"></i><i className="fas fa-slash"></i>
          </OfflineDiv>
        }
      </Online>*/}
      <NavigationBar viewIndex={3}></NavigationBar>

      <GenericModal
        display={displayDeleteAccountModal}
        onCloseClick={() => setDisplayDeleteAccountModal(false)}
        arrowYPosition={"28vh"}
        arrowXPosition={"6vw"}
        mainContent={
          <>
            {displayDeleteAccountConfirm ? (
              <>
                <h3>{"Sua conta foi excluída"}</h3>
                <br />
                <p>
                  {
                    "Caso queira montar novos ambientes, você pode criar uma nova conta!"
                  }
                </p>
              </>
            ) : (
              <>
                <h3>{"Deseja excluir sua conta?"}</h3>
                <br />
                <p>
                  {
                    "Ao excluir sua conta, você perderá todos os seus ambientes salvos."
                  }
                </p>
                <div className="buttons-div">
                  <button
                    className="alternative-button"
                    onClick={handleDeleteAccount}
                  >
                    Excluir conta
                  </button>
                  <button onClick={() => setDisplayDeleteAccountModal(false)}>
                    Cancelar
                  </button>
                </div>
              </>
            )}
          </>
        }
      ></GenericModal>
      {displayDeleteAccountModal && <ModalBackground></ModalBackground>}
    </>
  );
};

export default Profile;
