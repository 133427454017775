import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex: 1;
    height: ${props => props.innerHeight}px;
    width: 100vw;
    justify-content: center;
    align-items: center;
    background: url(https://images.unsplash.com/photo-1519710164239-da123dc03ef4?ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80), rgba(245, 245, 245, 0.7);
    background-blend-mode: overlay;
    background-size: 100% auto;
    font-family: 'Montserrat', sans-serif;
    min-height: 560px;
    overflow-y: auto;

    @media only screen and (min-width: 1024px) {
        background: url(https://images.unsplash.com/photo-1484154218962-a197022b5858?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1506&q=80), rgba(245, 245, 245, 0.9);
    }

    h2 {
        font-size: 14px;
        margin-top: 32px;
        padding-bottom: 10px;
        border-bottom: 3px solid ${props => props.brandColor};
        text-align: center;
        font-weight: 500;
    }

    h3 {
        margin-top: 20px;
        width: 280px;
    }

    h4 {
        font-size: 12px;
        margin-top: 16px;
        margin-bottom: 20px;
        text-align: center;
        font-weight: 500;
        width: 280px;
    }

    h5 {
        font-size: 11px;
        margin-bottom: 6px;
        text-align: center;
        color: ${props => props.alertColor};
        font-weight: 600;
    }

    h6 {
        font-size: 11px!important;
        margin-bottom: 3px;
        font-weight: 500;
    }

    div.input-container {
        display: flex;
        position: relative;
        align-items: center;
        width: 248px;
        height: 32px;
        margin: 0 auto 6px auto;
        background: ${props => props.backgroundColor};
        border-radius: 16px;
        color: rgba(20, 20, 20, 0.6);
        font-size: 14px;
        border: 1px solid rgba(20, 20, 20, 0.2);
        padding: 0 16px 0 16px;
    }

    div.input-container:focus-within,
    div.input-container:hover {
        border: 1px solid ${props => props.brandColor};
    }

    div.input-container input {
        background: ${props => props.backgroundColor};
        border: none;
        margin: auto auto auto 0;
        height: 100%;
        width: 100%;
        color: ${props => props.fontColor};
    }

    div.input-container i {
        opacity: 0.7;
    }

    div.socialLogin-container {
        display: none;
    }

    div.radio-container {
        width: 248px;
        margin: 0 auto 6px auto;
        background: #F5F5F5;
        border-radius: 16px;
        color: rgba(20,20,20,0.8);
        font-size: 12px;
        border: 1px solid rgba(20,20,20,0.2);
        padding: 8px 16px;
    }

    div.whereKnows {
        display: grid;
        grid-template-columns: 1fr 1fr;
        font-size:11px
    }

    div.whereKnows .form-check {
        display: flex;
        align-items: center;
    }

    div.whereKnows .form-check input {
        margin-right:2px;
    }

    .radio-title {
        margin: 6px 0px;
        font-weight: 600;
        text-align: center;
    }

    input.submit-button,
    input.next-button {
        display: flex;
        position: relative;
        width: 248px;
        height: 32px;
        border-radius: 16px;
        border: none;
        font-size: 12px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;
        font-family: 'Montserrat',sans-serif;
        font-weight: 500;
        margin-top: 6px;
    } 

    button.submit-button {
        display: flex;
        position: relative;
        width: 248px;
        height: 32px;
        border-radius: 16px;
        border: none;
        font-size: 12px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;
        font-family: 'Montserrat',sans-serif;
        font-weight: 500;
        margin-top: 6px;
    }

    button div.buttonload {
        animation: is-rotating 1.5s infinite;
        -webkit-animation: spin 1.5s linear infinite;
        border: 2.5px solid ${props => props.brandColor};
        border-radius: 50%;
        border-top-color: ${props => props.backgroundColor};
        height: 15px;
        width: 15px;
        background: ${props => props.brandColor};
    }

    @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    button:hover,
    a: hover,
    h6 a:hover {
        opacity: .8;
        cursor: pointer;
    }

    input.submit-button:hover,
    a: hover,
    h6 a:hover,
    input.next-button:hover {
        opacity: .8;
        cursor: pointer;
    }

    button.submit-button,
    input.submit-button,
    input.next-button {
        background: ${props => props.brandColor};
        color: ${props => props.brandContrastColor};
        font-weight: 400;
        text-align: center;
    }

    input.back-button {
        font-weight: 500;
        text-align: center;
        border: none;
        background: transparent;
        margin-bottom: 4px;
        font-size: 12px;
    }

    input.back-button:hover {
        cursor: pointer;
        opacity: .7;
    }

    button.social-login-button {
        background: ${props => props.backgroundColor};
        color: ${props => props.fontColor};
    }

    button.social-login-button i {
        font-size: 16px;
        color: #3B5998;
        margin-right: 6px;
    }

    button.social-login-button svg {
        margin-right: 4px;
    }

    h6 {
        font-size: 8px;
        text-align: center;
        font-weight: 500;
    }

    h6 a {
        font-size: 8px;
        color: #000;
    }

    a {
        font-size: 12px;
        color: #000;
        font-weight: 700;
        margin-top: 24px;
        text-decoration: underline;
    }
`

export const Header = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  flex: 1;
  height: 38px;
  width: 100vw;
  justify-content: center;
  align-items: center;
  background-color: rgba(245, 245, 245, 0.7);
  z-index: 1;
  box-shadow: 0 0.2px 4px rgba(0, 0, 0, 0.10);

  img {
    height: 24px;
  }
`

export const TermsModal = styled.div`
position: absolute;
width: 88vw;
height: auto;
background-color: ${props => props.backgroundColor};
border-radius: 16px;
box-shadow: 0 0.2px 4px rgba(0, 0, 0, 0.10);
flex-wrap: wrap;
flex: 1;
align-items: center;
align-self: center;
justify-content: start;
justify-self: center;
flex-direction: column;
opacity: 1;
z-index: 4;
padding: 16px;

@media only screen and (min-width: 768px) {
  width: 380px;
  z-index: 5;

  h3,
  p {
    width: 348px !important;
  }
}

h3 {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 700;
  color: ${props => props.brandColor};
  width: calc(88vw - 32px);
  flex: 1;
}

p {
  margin-bottom: 12px;
  font-size: 13px;
  color: ${props => props.fontColor};
  width: calc(88vw - 32px);
  height: 300px;
  overflow-y: auto;
}

i {
  position: absolute;
  right: 16px;
  font-size: 13px;
  color: ${props => props.fontColor};
}

i:hover {
  opacity: .8;
  cursor: pointer;
}
`