import React, { useContext, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// Styles
import { Container, Text } from "./styles";
import "react-dropdown/style.css";

import * as materialsActions from "../../../store/modules/materials/actions";

import { contractContext } from "../../../Contexts/contractContext";

import Loader from "../../../components/Loader";
import EditMaterials from "./components/EditMaterial";
import api from "../../../services/api";

const Materials = (props) => {
  const brandColor = useSelector(
    (state) => state.contractReducer.contract.config.brandColor
  );
  const backgroundColor = useSelector(
    (state) => state.contractReducer.contract.config.backgroundColor
  );
  const fontColor = useSelector(
    (state) => state.contractReducer.contract.config.fontColor
  );

  const { currentContract, setNewMaterialList } = useContext(contractContext);

  const dispatch = useDispatch();

  const materials = useSelector((state) => state.materialsReducer.materials);
  const isMaterialsLoading = useSelector(
    (state) => state.materialsReducer.isLoading
  );

  const [brands, setBrands] = useState([]);
  const [filtedBrands, setFilteredBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);

  const [currentMaterials, setCurrentMaterials] = useState([]);
  const [currentMaterialsFiltered, setCurrentMaterialsFiltered] = useState([]);

  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const [selectedMaterials, setSelectedMaterials] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [showEditMaterial, setShowEditMaterial] = useState(false);
  const [idMaterialEdit, setidMaterialEdit] = useState();

  const [searchBrandInputText, setSearchBrandInputText] = useState("");

  const [reproductions, setReproductions] = useState();
  const [surfaces, setSurfaces] = useState();
  const [types, setTypes] = useState();

  useEffect(() => {
    if (currentContract.materials) {
      const initialList = currentContract.materials.map((m) => m.material);
      setSelectedMaterials(initialList);
    }
  }, [currentContract]);

  useEffect(() => {
    isMaterialsLoading &&
      dispatch(materialsActions.getAllMaterialsReduced(null));

    setIsLoading(isMaterialsLoading);
  }, [isMaterialsLoading]);
  useEffect(() => {
    setFilteredBrands(brands);
  }, [brands]);

  useEffect(() => {
    if (materials.length > 0) {
      setBrands([
        ...new Set(
          materials
            .map((m) => m.brand)
            .sort(function (a, b) {
              if (a > b) {
                return 1;
              }
              if (a < b) {
                return -1;
              }
              return 0;
            })
        ),
      ]);

      api.get("/allAttributes").then((i) => {
        const reproduction = [];
        const surface = [];
        const type = [];

        i.data.reproductions.forEach((element) => {
          reproduction.push(element.reproduction);
        });
        i.data.surfaces.forEach((element) => {
          surface.push(element.surface);
        });
        i.data.types.forEach((element) => {
          type.push(element.type);
        });

        setReproductions(reproduction);
        setSurfaces(surface);
        setTypes(type);
      });
    }
  }, [materials]);

  useEffect(() => {
    if (reproductions) handleSort(reproductions);

    if (surfaces) handleSort(surfaces);

    if (types) handleSort(types);
  }, [reproductions, surfaces, types]);

  const handleSort = (array) => {
    if (array)
      array.sort(function (a, b) {
        if (a.description > b.description) {
          return 1;
        }
        if (a.description < b.description) {
          return -1;
        }
        return 0;
      });
  };

  const handleSelectedMaterials = (materialId, checked) => {
    let mHelper = selectedMaterials;

    if (checked) {
      if (!mHelper.includes(materialId)) mHelper.push(materialId);
    } else {
      mHelper.splice(mHelper.indexOf(materialId), 1);
    }

    setSelectedMaterials(mHelper);
    setNewMaterialList(mHelper);
  };

  useEffect(() => {
    if (localStorage.getItem("brandsFilter") && materials.length !== 0) {
      const filters = JSON.parse(localStorage.getItem("brandsFilter"));

      setSelectedBrands(filters);

      handleBrandFilter(filters);

      localStorage.removeItem("brandsFilter");
    }
  });

  const handleBrandCheck = (target) => {
    const sbHelper = selectedBrands;

    target.checked
      ? sbHelper.push(target.value) && setSelectAllChecked(false)
      : sbHelper.splice(sbHelper.indexOf(target.value), 1);
    setSelectedBrands(sbHelper);

    handleBrandFilter(sbHelper);
  };

  const handleBrandFilter = (sbHelper) => {
    const mHelper = materials
      .filter((m) => sbHelper.includes(m.brand))
      .map((m) => {
        return {
          id: m._id,
          miniThumbPath: m.miniThumbPath,
          description: m.description,
          brand: m.brand,
          format: m.format,
          isChecked: selectedMaterials.includes(m._id),
        };
      });

    mHelper.sort(function (a, b) {
      if (a.description < b.description) {
        return -1;
      } else {
        return true;
      }
    });

    setCurrentMaterials([...new Set(mHelper)]);
    setCurrentMaterialsFiltered([...new Set(mHelper)]);
  };

  const handleSelectAllMaterials = (target) => {
    let mHelper = [...new Set(currentMaterials)];

    setSelectAllChecked(target.checked);

    mHelper.forEach((m) => {
      m.isChecked = target.checked;
      handleSelectedMaterials(m.id, target.checked);
    });

    setCurrentMaterials(mHelper);
    setCurrentMaterialsFiltered(mHelper);
  };

  const handleCheckChildElement = (target) => {
    const mHelper = [...new Set(currentMaterials)];

    mHelper.forEach((m) => {
      if (m.id == target.value) {
        handleSelectedMaterials(m.id, target.checked);
        return (m.isChecked = target.checked);
      }
    });

    setCurrentMaterials(mHelper);
  };

  const filterRow = (a) => {
    var list = new Array();

    for (const i of currentMaterials) {
      if (
        i.description.toLowerCase().includes(a.toLowerCase()) ||
        i.format.toLowerCase().includes(a.toLowerCase())
      ) {
        list.push(i);
      }
    }

    setCurrentMaterialsFiltered(list);
  };

  useEffect(() => {
    if (searchBrandInputText !== "") {
      const newFilteredBrand = [];
      brands.forEach((brand) => {
        if (brand.toLowerCase().includes(searchBrandInputText.toLowerCase())) {
          newFilteredBrand.push(brand);
        }
      });
      setFilteredBrands(newFilteredBrand);
    } else {
      setFilteredBrands(brands);
    }
  }, [searchBrandInputText]);
  const handleBrandInput = (event) => {
    setSearchBrandInputText(event.target.value);
    return;
  };
  return (
    <Container
      backgroundColor={backgroundColor}
      fontColor={fontColor}
      brandColor={brandColor}
    >
      <h1>Materiais</h1>
      <div className="lists">
        {isLoading ? (
          <Loader borderRadius={"15px"} />
        ) : (
          <>
            <div className="brands">
              <h3>Marcas</h3>
              <input
                type="text"
                onChange={handleBrandInput}
                value={searchBrandInputText}
              ></input>
              <div className="brand-list">
                {filtedBrands.map((b, index) => (
                  <button key={index}>
                    <input
                      type="checkbox"
                      value={b}
                      checked={selectedBrands.includes(b)}
                      onChange={(e) => handleBrandCheck(e.target)}
                    />
                    {b}
                  </button>
                ))}
              </div>
            </div>

            <div className="material-list-header">
              <div className="selecionar-todos-checkbox">
                <form className="form">
                  <label>Pesquisar </label>
                  <input
                    type="text"
                    placeholder="Pesquisar"
                    onChange={(event) => {
                      filterRow(event.target.value);
                    }}
                  />
                </form>

                <div className="space"></div>

                <div className="form">
                  <input
                    type="checkbox"
                    checked={selectAllChecked}
                    onChange={(e) => handleSelectAllMaterials(e.target)}
                  />
                  Selecionar todos
                  {/* <button onClick={() => testList()}>TESTE</button> */}
                </div>
              </div>

              {currentMaterialsFiltered.length <= 0 ? (
                <Text>Selecione as marcas que deseja incluir no contrato</Text>
              ) : (
                <div className="material-list">
                  {currentMaterialsFiltered.map((m) => (
                    <div key={m.id} className="list__item">
                      <img
                        className="list__item__img"
                        loading="lazy"
                        quality={50}
                        src={m.miniThumbPath ? m.miniThumbPath : m.thumbPath}
                        alt={m.description}
                      />
                      <h2 className="list__item__title">{m.description}</h2>
                      <p className="list__item__text">{m.brand}</p>
                      <p className="list__item__text">{m.format}</p>
                      <input
                        type="checkbox"
                        value={m.id}
                        className="checkbox"
                        onChange={(t) => handleCheckChildElement(t.target)}
                        checked={m.isChecked}
                      ></input>
                      <span
                        onClick={() => {
                          setShowEditMaterial(true);
                          setidMaterialEdit(m.id);
                        }}
                      >
                        <i className="fas fa-pen"></i>
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </>
        )}
      </div>

      <EditMaterials
        showEditMaterial={showEditMaterial}
        setShowEditMaterial={setShowEditMaterial}
        idMaterial={idMaterialEdit}
        brands={brands}
        reproductions={reproductions}
        surfaces={surfaces}
        types={types}
        currentMaterialsFiltered={currentMaterialsFiltered}
        handleMainSessionClick={props.handleMainSessionClick}
        materials={materials}
        selectedBrands={selectedBrands}
      />
    </Container>
  );
};

export default Materials;
