import styled from "styled-components";

export const Text = styled.h3`
  font-size: 14px;
  color: ${(props) => props.fontColor};
  margin-top: 32px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
`;

export const Container = styled.ul`
  height: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: min-content;
  grid-gap: 8px;
  list-style: none;
  width: calc(100vw - 16px);
  margin: 0 8px;
  padding: 0 4px 4px 4px;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 200px);

  @media only screen and (min-height: 1750px) and (max-width: 1080px) {
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 12px;
  }

  @media only screen and (min-width: 1024px) and (max-height: 1750px) {
    width: 380px;
    height: calc(100vh - 174px);
    padding: 8px;
    margin: 0;

    .active {
      z-index: 5 !important;
    }

    div.space {
      height: 84px;
    }
  }

  div.space {
    height: 124px;
  }

  .list__item {
    border-radius: 8px;
    height: fit-content;
    padding-bottom: 8px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    background-color: ${(props) => props.backgroundColor};
    border: none;
    text-decoration: none;
    min-width: 3rem;
  }

  .list__item:focus {
    border-style: solid;
    border-color: #828282;
    border-width: 3.3px;
  }

  .list__item:hover {
    opacity: 0.8;
  }

  .active {
    z-index: 4;
  }

  .list__item__img {
    width: 100%;
    height: auto;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  .list__item__title {
    height: 30px;
    font-size: 11px;
    color: ${(props) => props.fontColor};
    margin: 0.2rem 0.6rem auto 0.6rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .list__item__text {
    color: ${(props) => props.fontColor};
    font-size: 10px;
    margin: auto 0.6rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;

    :last-child {
      height: 24px;
    }
  }
`;
