import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const MyRoute = ({ component: Component, isClosed, ...rest }) => {
  const hasToken =
    localStorage.getItem("@CRIA_TOKEN") ||
    sessionStorage.getItem("@CRIA_TOKEN");

  if (isClosed && !hasToken) {
    return <Redirect to={{ pathname: "/login" }} />;
  } else {
    return <Route {...rest} component={Component} />;
  }
};

MyRoute.defaultProps = {
  isClosed: false,
};

MyRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  isClosed: PropTypes.bool,
};

export default MyRoute;
