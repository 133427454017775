import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Container } from './styles'

const SimulatorTopBar = (props) => {

    const brandColor = useSelector(state => state.contractReducer.contract.config.brandColor)
    const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
    const fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)
    const brandContrastColor = useSelector(state => state.contractReducer.contract.config.brandContrastColor)
    const logoPath = useSelector(state => state.contractReducer.contract.config.logoPath)

    useEffect(() => {
        const TopBar = document.getElementById("TopBar")
        var iOS = (navigator.userAgent.match(/(iPad|iPhone|iPod)/i) ? true : false);

        if (iOS == true) {
            TopBar.style.marginTop = '20px';
        } else{
            TopBar.style.marginTop = '40px';
        }
    }, [])

    return (
        <Container backgroundColor={backgroundColor} fontColor={fontColor} brandColor={brandColor} brandContrastColor={brandContrastColor}>
            <div id="TopBar" className="navbar">
                <div className="arrow-left-button" onClick={props.onReturnClick}>
                    <i className="fas fa-home"></i>
                </div>
                <div className="undo-button" onClick={props.onUndoClick}>
                    <i className="fas fa-undo"></i>
                </div>
                <div className="expand-button" onClick={props.onFullScreenClick}>
                    <i className="fas fa-expand"></i>
                </div>
                <img src={logoPath} alt="Logotipo"></img>

                <div className="scene-materials" onClick={props.onListClick}>
                    Lista de Materiais
                </div>

                <div className="scene-list" onClick={props.onListClick}>
                    <i className="fas fa-list-ul"></i>
                </div>

                <div className="text" onClick={props.onFinishClick}>
                    CONCLUIR
                </div>
            </div>
        </Container>
    )

}

export default SimulatorTopBar