import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex: 1;
  height: ${(props) => props.innerHeight}px;
  width: 100vw;
  justify-content: center;
  align-items: center;
  background: url(https://images.unsplash.com/photo-1519710164239-da123dc03ef4?ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80),
    rgba(245, 245, 245, 0.7);
  background-blend-mode: overlay;
  background-size: 100% auto;
  font-family: "Montserrat", sans-serif;
  min-height: 500px;
  overflow-y: auto;

  @media only screen and (min-width: 1024px) {
    background: url(https://images.unsplash.com/photo-1484154218962-a197022b5858?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1506&q=80),
      rgba(245, 245, 245, 0.9);
  }

  h2 {
    font-size: 14px;
    margin-top: 32px;
    padding-bottom: 10px;
    border-bottom: 3px solid ${(props) => props.brandColor};
    text-align: center;
    font-weight: 500;
  }

  h4 {
    font-size: 12px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 500;
  }

  h5 {
    font-size: 11px;
    margin-bottom: 6px;
    text-align: center;
    color: ${(props) => props.alertColor};
    font-weight: 600;
  }

  div.input-container {
    display: flex;
    position: relative;
    align-items: center;
    width: 222px;
    height: 32px;
    margin: 0 auto 6px auto;
    background: ${(props) => props.backgroundColor};
    border-radius: 16px;
    color: rgba(20, 20, 20, 0.6);
    font-size: 14px;
    border: 1px solid rgba(20, 20, 20, 0.2);
    padding: 0 10px 0 0;
  }

  div.input-container:focus-within,
  div.input-container:hover {
    border: 1px solid ${(props) => props.brandColor};
  }

  div.input-container input {
    background: ${(props) => props.backgroundColor};
    border: none;
    margin: auto auto auto 0;
    height: 100%;
    width: 100%;
    padding-left: 10px;
    color: ${(props) => props.fontColor};
  }

  div.input-container i {
    opacity: 0.7;
    margin-left: 9px;
    color: ${(props) => props.fontColor};
  }

  div.input-container .verifyUser,
  div.input-container .verifyUserErr {
    position: absolute;
    right: 45px;
    font-size: 12px;
  }

  div.input-container .verifyUser i {
    color: #07a60a;
  }

  div.input-container .verifyUserErr i {
    color: red;
  }

  div.keep-login {
    display: flex;
    width: 222px;
    padding: 4px 8px;
    input {
      width: 14px;
      height: 14px;
      margin-right: 8px;
    }
  }

  div.socialLogin-container {
    display: none;
  }

  div.register-container {
    background: transparent;
    border: 1px solid #949494;
    border-radius: 15px;
    padding: 10px;
    margin-top: 2.5rem;
    text-align: center;
  }

  div.register-container p {
    font-weight: 500;
    padding: 12px;
  }

  input {
    display: flex;
    position: relative;
    width: 222px;
    height: 32px;
    border-radius: 16px;
    border: none;
    font-size: 12px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
  }

  button.register-button {
    display: flex;
    position: relative;
    width: 222px;
    height: 32px;
    border-radius: 16px;
    border: none;
    font-size: 12px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    transition: 0.2s;
  }

  button.old-version-button {
    display: flex;
    position: relative;
    width: 222px;
    height: 32px;
    border-radius: 16px;
    border: none;
    font-size: 12px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    justify-content: center;
    align-items: center;
    margin-top: 48px;
    margin-bottom: 0px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    transition: 0.2s;
    background: #e0a800;
  }

  button.old-version-button a {
    color: #ffffff;
    background: transparent;
    text-decoration: none;
    margin-top: 0px !important;
    font-weight: 600;
    font-size: 12px;
  }

  button {
    display: flex;
    position: relative;
    width: 222px;
    height: 32px;
    border-radius: 16px;
    border: none;
    font-size: 12px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    transition: 0.2s;
  }

  button div.buttonload {
    animation: is-rotating 1.5s infinite;
    -webkit-animation: spin 1.5s linear infinite;
    border: 2.5px solid ${(props) => props.brandColor};
    border-radius: 50%;
    border-top-color: ${(props) => props.backgroundColor};
    height: 15px;
    width: 15px;
    background: ${(props) => props.brandColor};
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  button div.buttonload2 {
    animation: is-rotating 1.5s infinite;
    -webkit-animation: spin2 1.5s linear infinite;
    border: 2.5px solid #e5e5e5;
    border-radius: 50%;
    border-top-color: #0048ff;
    height: 15px;
    width: 15px;
    background: #0048ff;
  }

  @-webkit-keyframes spin2 {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin2 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  input:hover,
  a:hover,
  small:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  button:hover,
  a:hover,
  small:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  input.submit-button,
  button.register-button {
    background: ${(props) => props.brandColor};
    color: ${(props) => props.brandContrastColor};
    font-weight: 600;
    margin-top: 0.2rem;
  }

  button.submit-button {
    background: ${(props) => props.brandColor};
    color: ${(props) => props.brandContrastColor};
    font-weight: 600;
    margin-top: 0.2rem;
    transition: 0.2s;
  }

  button.buttons-div {
    background: ${(props) => props.brandColor};
    color: white;
    margin-top: 0.2rem;
    display: flex;
    position: relative;
    width: 130px !important;
    height: 32px !important;
    border-radius: 16px;
    border: none;
    font-size: 12px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    transition: 0.2s;
    margin-left: calc(100% - 130px) !important;
  }

  button.submit-button2 {
    background: ${(props) => props.brandColor};
    color: ${(props) => props.brandContrastColor};
    font-weight: 600;
    margin-top: 0.2rem;
    transition: 0.2s;
  }

  button.register-button a {
    color: ${(props) => props.brandContrastColor};
    background: ${(props) => props.brandColor};
    text-decoration: none;
    margin-top: 0px !important;
    font-weight: 600;
    font-size: 12px;
  }

  button.social-login-button {
    background: ${(props) => props.backgroundColor};
    color: ${(props) => props.fontColor};
  }

  button.social-login-button i {
    font-size: 16px;
    color: #3b5998;
    margin-right: 6px;
  }

  button.social-login-button svg {
    margin-right: 4px;
  }

  h6 {
    font-size: 8px;
    text-align: center;
    font-weight: 500;
  }

  small {
    font-size: 12px;
    color: #000;
    text-decoration: underline;
    font-weight: 900;
  }

  a {
    font-size: 14px;
    color: ${(props) => props.brandContrastColor};
    background: ${(props) => props.brandColor};
    font-weight: 900;
    margin-top: 36px;
    text-decoration: underline;
  }
`;

export const Header = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  flex: 1;
  height: 38px;
  width: 100vw;
  justify-content: center;
  align-items: center;
  background-color: rgba(245, 245, 245, 0.7);
  z-index: 1;
  box-shadow: 0 0.2px 4px rgba(0, 0, 0, 0.1);

  img {
    height: 24px;
  }
`;
