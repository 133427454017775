import React, { useEffect, useState } from "react";
import { Container } from "./styles";
import Loader from "react-spinners/SyncLoader";
import { useDispatch } from "react-redux";

import api from "../../../../../services/api";

import * as materialsActions from "../../../../../store/modules/materials/actions";

const EditMaterials = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [confirmRemove, setConfirmRemove] = useState(false);
  const [success, setSuccess] = useState(false);
  const [materialData, setMaterialData] = useState();

  const dispatch = useDispatch();

  const [values, setValues] = useState({
    description: "",
    format: "",
    brand: "",
    color: "",
    type: {
      application: "",
      description: "",
    },
    reproduction: {
      description: "",
      thumbPath: "",
    },
    surface: {
      abbreviation: "",
      brightness: "",
      description: "",
    },
  });

  useEffect(() => {
    if (props.idMaterial && props.showEditMaterial) {
      setIsLoading(true);
      setConfirmRemove(false);
      setSuccess(false);

      api.get(`/material/${props.idMaterial}`).then((data) => {
        setIsLoading(false);
        if (data?.data?.material) setMaterialData(data.data.material);
      });
    }
  }, [props]);

  useEffect(() => {
    if (
      props.idMaterial &&
      materialData &&
      props.types &&
      props.surfaces &&
      props.reproductions
    ) {
      setValues({
        description: materialData.description,
        format: materialData.format,
        brand: materialData.brand,
        color: materialData.color,
        type: {
          application: materialData.type?.application,
          description: materialData.type?.description,
        },
        reproduction: {
          description: materialData.reproduction?.description,
          thumbPath: materialData.reproduction?.thumbPath,
        },
        surface: {
          abbreviation: materialData.surface?.abbreviation,
          brightness: materialData.surface?.brightness,
          description: materialData.surface?.description,
        },
      });
    }
  }, [props, materialData]);

  const handleChangeValue = (whatData, data) => {
    if (whatData === "reproduction") {
      const findReproduction = props.reproductions.find(
        (e) => e.description === data
      );

      if (findReproduction)
        setValues({
          ...values,
          reproduction: {
            description: findReproduction.description,
            thumbPath: findReproduction.thumbPath,
          },
        });
    }

    if (whatData === "surface") {
      const findSurfaces = props.surfaces.find((e) => e.description === data);

      if (findSurfaces)
        setValues({
          ...values,
          surface: {
            abbreviation: findSurfaces.abbreviation,
            brightness: findSurfaces.brightness,
            description: findSurfaces.description,
          },
        });
    }

    if (whatData === "type") {
      const findTypes = props.types.find((e) => e.description === data);

      if (findTypes)
        setValues({
          ...values,
          type: {
            description: findTypes.description,
            application: findTypes.application,
          },
        });
    }
  };

  const handleUpdate = () => {
    setIsLoading(true);

    const { description, format, brand, color } = values;
    const { handleMainSessionClick } = props;

    api.put(`/material/${props.idMaterial}`, values).then((e) => {
      if (e.data?.success) {
        setSuccess(true);

        updateMaterials(props.materials, props.idMaterial, {
          description,
          format,
          brand,
          color,
          reproduction: { description: values.reproduction.description },
          surface: { description: values.surface.description },
          type: { description: values.type.description },
        });

        setTimeout(() => {
          localStorage.setItem(
            "brandsFilter",
            JSON.stringify(props.selectedBrands)
          );
          handleMainSessionClick("");
          handleMainSessionClick("materials");
        }, 1000);
      }
    });
  };

  function updateMaterials(materials, idMaterial, updatedValues) {
    for (const element of materials) {
      if (element._id === idMaterial) {
        Object.assign(element, updatedValues);
      }
    }
  }

  const handleRemove = () => {
    setIsLoading(true);
    const { handleMainSessionClick } = props;

    api.delete(`/material/${props.idMaterial}`).then((e) => {
      if (e.data?.success) {
        setSuccess(true);

        setTimeout(() => {
          const findM = props.materials.findIndex(
            (i) => i._id === props.idMaterial
          );

          props.materials.splice(findM, 1);

          localStorage.setItem(
            "brandsFilter",
            JSON.stringify(props.selectedBrands)
          );
          handleMainSessionClick("");
          handleMainSessionClick("materials");
        }, 1000);
      }
    });
  };

  const handleClose = () => {
    props.setShowEditMaterial(false);
    setIsLoading(false);
    setSuccess(false);
  };

  return (
    <Container>
      {props.showEditMaterial && (
        <>
          <div
            className="bk"
            onClick={() => props.setShowEditMaterial(false)}
          ></div>

          <div className="content">
            <div className="close" onClick={() => handleClose()}>
              <i class="fas fa-times"></i>
            </div>
            {success ? (
              <div className="success">
                <p>Sucesso! Aguarde...</p>
                <span>
                  <i class="fas fa-check"></i>
                </span>
              </div>
            ) : (
              <>
                {!confirmRemove ? (
                  <>
                    {isLoading || !materialData ? (
                      <>
                        <div className="loading">
                          <Loader color="gray" size={8} />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="container">
                          <p>Editar Material</p>
                          <div className="inputClass">
                            <div className="inputItem">
                              <label>Nome material</label>
                              <input
                                value={values.description}
                                onChange={(e) =>
                                  setValues({
                                    ...values,
                                    description: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="inputClass">
                            <div className="inputItem">
                              <label>Marca</label>
                              <select
                                class="form-select form-select-lg mb-3"
                                aria-label=".form-select-lg example"
                                onChange={(e) =>
                                  setValues({
                                    ...values,
                                    brand: e.target.value,
                                  })
                                }
                              >
                                {props.brands?.map((i) => (
                                  <option
                                    value={i}
                                    selected={i === materialData.brand}
                                  >
                                    {i}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="inputItem">
                              <label>Formato</label>
                              <input
                                value={values.format}
                                onChange={(e) =>
                                  setValues({
                                    ...values,
                                    format: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="inputClass">
                            <div className="inputItem">
                              <label>Reprodução</label>

                              <select
                                class="form-select form-select-lg mb-3"
                                aria-label=".form-select-lg example"
                                onChange={(e) =>
                                  handleChangeValue(
                                    "reproduction",
                                    e.target.value
                                  )
                                }
                              >
                                {props.reproductions?.map((i) => (
                                  <option
                                    value={i.description}
                                    selected={
                                      i.description ===
                                      materialData.reproduction?.description
                                    }
                                  >
                                    {i.description}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="inputItem">
                              <label>Superfície</label>

                              <select
                                class="form-select form-select-lg mb-3"
                                aria-label=".form-select-lg example"
                                onChange={(e) =>
                                  handleChangeValue("surface", e.target.value)
                                }
                              >
                                {props.surfaces?.map((i) => (
                                  <option
                                    value={i.description}
                                    selected={
                                      i.description ===
                                      materialData.surface?.description
                                    }
                                  >
                                    {i.description}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="inputClass">
                            <div className="inputItem">
                              <label>Tipo</label>

                              <select
                                class="form-select form-select-lg mb-3"
                                aria-label=".form-select-lg example"
                                onChange={(e) =>
                                  handleChangeValue("type", e.target.value)
                                }
                              >
                                {props.types?.map((i) => (
                                  <option
                                    value={i.description}
                                    selected={
                                      i.description ===
                                      materialData.type?.description
                                    }
                                  >
                                    {i.description}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="inputItem">
                              <label>Cor</label>
                              <input
                                value={values.color}
                                onChange={(e) =>
                                  setValues({
                                    ...values,
                                    color: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="buttons">
                            <button onClick={() => setConfirmRemove(true)}>
                              Excluir produto
                            </button>
                            <button onClick={() => handleUpdate()}>
                              Salvar
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <div className="delete">
                    <p>Deseja excluir o material?</p>
                    <div>
                      <button onClick={() => setConfirmRemove(false)}>
                        Cancelar
                      </button>
                      <button onClick={() => handleRemove()}>Excluir</button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
    </Container>
  );
};

export default EditMaterials;
