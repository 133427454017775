import styled from 'styled-components'

export const Container = styled.div`
display: flex;
width: 490px;
height: 390px;
position: absolute;
margin-top: 8%;
margin-left: 32%;
background-color: #e5e5e5;
justify-content: center;
align-items: center;
box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
border-radius: 15px;

@media only screen and (min-width: 1919px) {
  margin-left: 37.5% !important;
  margin-top: 15%!important;
}

@media only screen and (max-width: 1024px) {
  width: 300px;
  height: 200px;
  margin-top: 50%;
  margin-left: 9%;
  background-color: ${props => props.backgroundColor};

  div.form {
    width: 300px;
    height: 200px;
  }
}

h5 {
  display: flex;
  position: absolute;
  font-size: 13px;
  margin-bottom: 6px;
  text-align: center;
  color: red;
  font-weight: bold;
  margin-top: 25px;
  margin-left: 24px;
}

div.form {
  display: flex;
  border-radius: 15px;
  flex-direction: column;
  width: 480px;
  height: 380px;
  background: ${props => props.backgroundColor};
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}

label {
  margin-top: 10px;
  margin-left: 18px;
  margin-bottom: 3px;
  font-family: 'Montserrat', sans-serif;
  color: ${props => props.brandColor};
  font-weight: bold;
}

input[type=text], select {
  width: 90%;
  margin-left: 12px;
  padding: 8px 10px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 15px;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

input[type=tel], select {
  width: 90%;
  margin-left: 12px;
  padding: 8px 10px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 15px;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

input[type=password], select {
  width: 90%;
  margin-left: 12px;
  padding: 8px 10px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 15px;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

input[type=submit] {
  width: 25%;
  background-color: ${props => props.brandColor};
  color: ${props => props.backgroundColor};
  padding: 10px 12px;
  margin-left: calc(100% - 32.3%);
  border: none;
  border-radius: 15px;
  cursor: pointer;
  margin-top: 18px;
  transition: 0.2s;
  font-family: 'Montserrat', sans-serif;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}

button {
  width: 25%;
  background-color: ${props => props.brandColor};
  color: white;
  padding: 10px 12px;
  margin-left: calc(100% - 32.3%);
  border: none;
  border-radius: 15px;
  cursor: pointer;
  margin-top: 18px;
  transition: 0.2s;
  font-family: 'Montserrat', sans-serif;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}

button .c-loader {
  animation: is-rotating 1.5s infinite;
  -webkit-animation: spin 1.5s linear infinite;
  border: 2.5px solid ${props => props.backgroundColor};
  border-radius: 50%;
  border-top-color: ${props => props.brandColor};
  height: 15px;
  width: 15px;
  background: ${props => props.brandColor};
  margin-left: 42%;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

input[type=submit]:hover {
  opacity: 0.7;
}
`

export const Main = styled.div`  
  display: flex;
  flex-direction: column;
  align-content: space-between;
  flex-wrap: wrap;
  background-color: ${props => props.backgroundColor};
  justify-content: flex-end;
  font-family: 'Montserrat', sans-serif;
  height: 100vh;
  width: 100wh;
  overflow-y: auto;
  overflow-x: hidden;    
  margin-top: 38px;
  padding: 0px;
  height: calc(100vh - 40px);
`

export const MainContent = styled.div`
  align-items: flex-start;
  flex-direction: column;
  background-color: ${props => props.backgroundColor};  
  height: 100vh;
  width: calc(100vw - 240px);
  overflow-y: auto;
  overflow-x: hidden;  
`

export const OptionsList = styled.div`
  max-width: 240px;
  padding: 16px;
  height: 100vh;
  display: flex;
  flex-direction: column;    
  justify-content: end;
  border-right: #dadada 1px;
  box-shadow: 0 0.2px 4px rgb(0 0 0 / 10%);

  @media only screen and (max-width: 767px) {
    height: calc(100vh - 75px);
    justify-content: flex-start;
  }

  div.option {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: start;
    cursor: pointer;
  }

  div.option:hover {
    opacity: .8;
  }

  div.option i {
    font-size: 16px;
    margin-left: 4px;
    color: ${props => props.brandColor};
  }

  div.option h3 {
    margin-left: 8px;
    font-size: 13px;
    font-weight: 500;
    color: ${props => props.fontColor};
  }

  div.sub-option {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: start;
    cursor: pointer;
    padding-left: 2.5rem;
  }

  div.sub-option:hover {
    opacity: .8
  }

  div.sub-option h4 {
    font-size: 11px;
    font-weight: 500;
    color: ${props => props.fontColor};
  }
  
`

export const OfflineDiv = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  width: fit-content;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  background-color: ${props => props.backgroundColor};
  position: absolute;
  bottom: 50px;
  align-self: center;
  z-index: 8;
  font-size: 11px;
  font-weight: 500;
  padding-left: 16px;
  border-radius: 10px;
  opacity: .9;
  box-shadow: 0 0.2px 4px rgba(0, 0, 0, 0.10);

  i.fa-wifi {
    position: relative;
    margin-left: 12px;
    font-size: 12px;
  }

  i.fa-slash {
    position: relative;
    right: 15px;
    z-index: 9;
    font-size: 12px;
  }
`
