import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: 244px;
  width: 300px;
  /* margin: 0 .4rem 1.4rem .4rem; */
  margin: 0px 12px 24px 12px;
  transition: 0.3s;
  cursor: pointer;
  box-shadow: 2px 2px 4px rgb(0 0 0 / 30%);
  border-radius: 15px;

  img {
    position: relative;
    height: 244px;
    width: 300px;
    border-radius: 15px;
    object-fit: cover;
  }

  @media only screen and (min-width: 1919px) {
    height: 340px;
    width: 384px;
    margin: 0 .6rem 1.4rem .6rem;

    img {
      height: 340px;
      width: 384px;
    }
  }

  @media only screen and (max-width: 1229px) {
    height: 230px;
    width: 290px;
    margin: 0 .6rem 1.4rem .6rem;

    img {
      height: 230px;
      width: 290px;
    }
  }

  @media only screen and (max-width: 700px) {
    height: 190px;
    width: 260px;
    margin: 0 .6rem 1.4rem .6rem;

    img {
      height: 190px;
      width: 260px;
    }
  }

  @media only screen and (max-width: 480px) {
    height: 170px;
    width: 240px;
    margin: 0 .6rem 1.4rem .6rem;

    img {
      height: 170px;
      width: 240px;
    }
  }

  div.card-shadow {
    height: 100%;
    width: 100%;
    position: absolute;
    border-radius: 15px;
    background: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,.9));
    bottom: 0;
  }

  div.card-not-shadow {
    height: 100%;
    width: 100%;
    position: absolute;
    border-radius: 15px;
    bottom: 0;
  }

  h4 {
    position: absolute;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    margin: .6rem .8rem 1.6rem .8rem;
    bottom: 0;
  }

  h4.Lead {
    position: absolute;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    margin: .6rem .8rem .6rem .8rem;
    bottom: 0;
  }

  h4.status {
    position: absolute;
    font-size: 10px;
    color: ${props => props.backgroundColor};
    margin: .6rem .8rem 3.3rem .8rem;
    bottom: 0;
  }

  h4.name{
    position: absolute;
    font-size: 10px;
    color: #fff;
    margin: .6rem .8rem .6rem .8rem;
    bottom: 0;
  }

  .drop-status {
    transform: rotate(-90deg);
    transition: 0.3s;
  }

  .btn-trash {
    position: absolute;
    border: none;
    background-color: transparent;
    font-size: 14px;
    color: white;
    right: 0px;
    bottom: 0px;
    margin: 12px 16px;
  }

  .btn-trash:hover {
    opacity: .7;
  }

  .btn-trash i {

  }

 //---------------------------------------//
  div.open{
    width: 98px;
    height: 20px;
    background: orange;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
  }

  div.open:hover{
    border-radius: 8px 8px 0px 0px;
  }

  div.open:hover i{
    transform: rotate(0deg);
  }

  div.menuContainerOpen {
    border-radius: 0px 0px 8px 8px;
    height: 62px;
    width: 98px;
    position: absolute;
    margin-top: 80px;
    background-color: orange;
    padding-top: 5px;
    flex-direction: column;
    opacity: 0;
    transition: 0.3s;
  }

  div.open:hover div.menuContainerOpen{
    opacity: 1;
  }

//---------------------------------------//
  div.closed{
    width: 115px;
    height: 20px;
    background: green;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
  }

  div.closed:hover{
    border-radius: 8px 8px 0px 0px;
  }

  div.closed:hover i{
    transform: rotate(0deg);
  }

  div.menuContainerClosed {
    border-radius: 0px 0px 8px 8px;
    height: 63px;
    width: 115px;
    position: absolute;
    margin-top: 80px;
    background-color: green;
    padding-top: 5px;
    flex-direction: column;
    opacity: 0;
    transition: 0.3s;
  }

  div.closed:hover div.menuContainerClosed{
    opacity: 1;
  }

//---------------------------------------//
  div.cancelled{
    width: 105px;
    height: 20px;
    background: red;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.cancelled:hover{
    border-radius: 8px 8px 0px 0px;
  }

  div.cancelled:hover i{
    transform: rotate(0deg);
  }

  div.menuContainerCancelled {
    border-radius: 0px 0px 8px 8px;
    height: 62px;
    width: 105px;
    position: absolute;
    margin-top: 78px;
    background-color: red;
    padding-top: 5px;
    flex-direction: column;
    opacity: 0;
    transition: 0.3s;
  }

  div.cancelled:hover div.menuContainerCancelled{
    opacity: 1;
  }

//---------------------------------------//
  div.content{
    display: flex;
    width: 100%;
    height: 25px;
    align-items: center;
    padding-left: 5px;
  }

  div.content:hover{
    background: rgba(0,0,0,.2);
  }

  a {
    color: #fff;
    font-weight: bold;
  }
`