import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import api from "../../../../../../services/api";

// Styles
import { Container, Cell } from "./styles";

const MaterialsFilter = (props) => {
  const [colors, setColors] = useState([]);
  const [brands, setBrands] = useState([]);
  const [formats, setFormats] = useState([]);
  const [surface, setSurface] = useState([]);
  const [currentTab, setCurrentTab] = useState(1);

  const brandColor = useSelector(
    (state) => state.contractReducer.contract.config.brandColor
  );
  const backgroundColor = useSelector(
    (state) => state.contractReducer.contract.config.backgroundColor
  );
  const fontColor = useSelector(
    (state) => state.contractReducer.contract.config.fontColor
  );

  useEffect(() => {
    if (props.materials && props.materials.materials) {
      const brands = props.materials.materials.map((item) => item.brand);
      const formats = props.materials.materials.map((item) => item.format);
      const colors = props.materials.materials.map((item) => item.color);

      const surface = props.materials.materials.map((item) => item.surface);

      const filteredBrands = brands.filter((v, i) => brands.indexOf(v) === i);
      const filteredFormats = formats.filter(
        (v, i) => formats.indexOf(v) === i
      );
      const filteredColors = colors.filter((v, i) => colors.indexOf(v) === i);
      const filteredSurfaces = surface.filter(
        (v, i) => surface.indexOf(v) === i
      );

      setBrands(filteredBrands);
      setFormats(filteredFormats);
      setColors(filteredColors);
      setSurface(filteredSurfaces);
    }
  }, [props.materials]);

  useEffect(() => {
    if (props.filters && props.materials) {
      api
        .get(
          `material/contract/${props.contractId}/${props.selectedTypeMaterials.category}/color?reproduction=${props.selectedTypeMaterials.type}`
        )
        .then((data) => {
          setColors(data.data.colors);
        });
      api
        .get(
          `material/contract/${props.contractId}/${props.selectedTypeMaterials.category}/brand?reproduction=${props.selectedTypeMaterials.type}`
        )
        .then((data) => {
          setBrands(data.data.brands);
        });
      api
        .get(
          `material/contract/${props.contractId}/${props.selectedTypeMaterials.category}/format?reproduction=${props.selectedTypeMaterials.type}`
        )
        .then((data) => {
          setFormats(data.data.format);
        });
      api
        .get(
          `material/contract/${props.contractId}/${props.selectedTypeMaterials.category}/surface?reproduction=${props.selectedTypeMaterials.type}`
        )
        .then((data) => {
          setSurface(data.data.surface);
        });
    } else {
    }
  }, [props.filters]);

  const handleSelectColor = (color) => {
    const alreadySelected = props.filters.color.findIndex(
      (item) => item === color
    );

    if (alreadySelected >= 0) {
      const filteredItems = props.filters.color.filter(
        (item) => item !== color
      );
      props.setFilters({
        ...props.filters,
        color: filteredItems,
      });
    } else {
      props.setFilters({
        ...props.filters,
        color: [...props.filters.color, color],
      });
    }
  };

  const handleSelectBrand = (brand) => {
    const alreadySelected = props.filters.brand.findIndex(
      (item) => item === brand
    );

    if (alreadySelected >= 0) {
      const filteredItems = props.filters.brand.filter(
        (item) => item !== brand
      );
      props.setFilters({
        ...props.filters,
        brand: filteredItems,
      });
    } else {
      props.setFilters({
        ...props.filters,
        brand: [...props.filters.brand, brand],
      });
    }
  };

  const handleSelectFormat = (format) => {
    const alreadySelected = props.filters.format.findIndex(
      (item) => item === format
    );

    if (alreadySelected >= 0) {
      const filteredItems = props.filters.format.filter(
        (item) => item !== format
      );
      props.setFilters({
        ...props.filters,
        format: filteredItems,
      });
    } else {
      props.setFilters({
        ...props.filters,
        format: [...props.filters.format, format],
      });
    }
  };

  const handleSelectSurface = (surface) => {
    const alreadySelected = props.filters.surface.findIndex(
      (item) => item === surface
    );

    if (alreadySelected >= 0) {
      const filteredItems = props.filters.surface.filter(
        (item) => item !== surface
      );
      props.setFilters({
        ...props.filters,
        surface: filteredItems,
      });
    } else {
      props.setFilters({
        ...props.filters,
        surface: [...props.filters.surface, surface],
      });
    }
  };

  return (
    <Container
      modal={props.modal}
      backgroundColor={backgroundColor}
      brandColor={brandColor}
      fontColor={fontColor}
    >
      <nav className="navbar">
        <i
          className="fas fa-arrow-left"
          onClick={() => props.setModal(false)}
        ></i>
        <h1>Filtros</h1>
      </nav>

      <div className="filter-nav-bar">
        <div
          onClick={() => setCurrentTab(1)}
          className={currentTab == 1 ? "selected" : ""}
        >
          <h3>COR</h3>
        </div>
        <div
          onClick={() => setCurrentTab(2)}
          className={currentTab == 2 ? "selected" : ""}
        >
          <h3>MARCA</h3>
        </div>
        <div
          onClick={() => setCurrentTab(3)}
          className={currentTab == 3 ? "selected" : ""}
        >
          <h3>FORMATO</h3>
        </div>
        <div
          onClick={() => setCurrentTab(4)}
          className={currentTab == 4 ? "selected" : ""}
        >
          <h3>SUPERFÍCIE</h3>
        </div>
      </div>

      {currentTab == 1 && (
        <div className="general-filter">
          <h1 className="section__title">Cor</h1>
          <ul className="section__list">
            {colors.map((item) => (
              <li key={item}>
                <button
                  className={
                    props.filters.color.includes(item)
                      ? "filter-tag selected"
                      : "filter-tag"
                  }
                  onClick={(e) => handleSelectColor(item)}
                >
                  {item}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {currentTab == 2 && (
        <div className="brands-filter">
          <h1 className="section__title">Marca</h1>
          <ul className="section__list">
            {brands.map((item) => (
              <li key={item}>
                <button
                  className={
                    props.filters.brand.includes(item)
                      ? "filter-tag selected"
                      : "filter-tag"
                  }
                  onClick={(e) => handleSelectBrand(item)}
                >
                  {item}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {currentTab == 3 && (
        <div className="size-filter">
          <h1 className="section__title">Formato</h1>
          <ul className="section__list">
            {formats.map((item) => (
              <li key={item}>
                <button
                  className={
                    props.filters.format.includes(item)
                      ? "filter-tag selected"
                      : "filter-tag"
                  }
                  onClick={(e) => handleSelectFormat(item)}
                >
                  {item}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {currentTab == 4 && (
        <div className="size-filter">
          <h1 className="section__title">Superfície</h1>
          <ul className="section__list">
            {surface.map((item) => (
              <li key={item}>
                <button
                  className={
                    props.filters.surface.includes(item)
                      ? "filter-tag selected"
                      : "filter-tag"
                  }
                  onClick={(e) => handleSelectSurface(item)}
                >
                  {item}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      <Cell
        backgroundColor={backgroundColor}
        fontColor={fontColor}
        brandColor={brandColor}
      >
        {props.filters.color.length > 0 ||
        props.filters.brand.length > 0 ||
        props.filters.format.length > 0 ||
        props.filters.surface.length > 0 ? (
          <button
            className="replace-button"
            onClick={() =>
              props.setFilters({
                color: [],
                brand: [],
                format: [],
                surface: [],
              })
            }
          >
            Limpar filtros (
            {props.filters.color.length +
              props.filters.brand.length +
              props.filters.format.length +
              props.filters.surface.length}
            )
          </button>
        ) : (
          ""
        )}
        <button
          className="new-environment-button"
          onClick={() => props.setModal(false)}
        >
          Aplicar filtros
        </button>
      </Cell>
    </Container>
  );
};

export default MaterialsFilter;
