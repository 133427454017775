import React from "react";
import { Switch, BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import * as contractActions from "../store/modules/contract/actions";

import MyRoute from "./MyRoute";

import NewRoom from "./../pages/NewRoom";
import Simulator from "./../pages/Simulator";
import Login from "./../pages/Login";
import CreateAccount from "./../pages/CreateAccount";
import Orders from "./../pages/Orders";
import Profile from "./../pages/Profile";
import NewPassword from "./../pages/NewPassword";
import PrivacyPolicy from "./../pages/PrivacyPolicy";
import Dashboard from "../pages/Dashboard";
import Alls from "../pages/Alls";
import AllsMyOrders from "../pages/allsMyOrders";
import Tutorial from "./../pages/Tutorial";
import Contract from "./../pages/Contracts";

const Routes = () => {
  const isContractLoading = useSelector(
    (state) => state.contractReducer.contract.isLoading
  );
  const dispatch = useDispatch();
  //const user = useSelector((state) => state.loginReducer.user.login);
  const userId = useSelector((state) => state.loginReducer.user.id);
  const user = useSelector((state) => state.loginReducer.user);
  const contract = useSelector((state) => state.contractReducer.contract);

  useEffect(() => {
    userId && dispatch(contractActions.getContract(userId));
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <MyRoute exact path="/novo-ambiente" component={NewRoom} isClosed />
        <MyRoute exact path="/simulador" component={Simulator} isClosed />
        <MyRoute exact path="/criar-conta" component={CreateAccount} />
        <MyRoute exact path="/tutorial" component={Tutorial} />
        <MyRoute exact path="/contract" component={Contract} />
        <MyRoute exact path="/login" component={Login} />
        <MyRoute exact path="/" component={Orders} isClosed />
        <MyRoute exact path="/minha-conta" component={Profile} isClosed />
        <MyRoute exact path="/redefinir-senha" component={NewPassword} />
        <MyRoute
          exact
          path="/politica-de-privacidade"
          component={PrivacyPolicy}
        />
        <MyRoute exact path="/relatorios" component={Dashboard} />
        <MyRoute exact path="/ver-tudo-minha-conta" component={Alls} />
        <MyRoute exact path="/ver-tudo" component={AllsMyOrders} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
