import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: no-wrap;
  height: auto;
  width: 100vw;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  z-index: 3;
  font-family: 'Montserrat', sans-serif;

  @media only screen and (min-width: 1024px) {
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    z-index: 4;
    justify-content: center;

    div#TopBar {
      display: flex;
      flex: 1;
      flex-wrap: no-wrap;
      height: 40px;
      width: 100vw;
      align-items: start;
      background-color: ${props => props.backgroundColor};
      box-shadow: 0 0.2px 4px rgba(0, 0, 0, 0.10);
      justify-content: center !important;
      animation-name: example2;
      animation-duration: 2s;  
      animation-fill-mode: forwards;
      padding: 0px;

      @keyframes example2 {
        from {margin-top: -40px;}
          to {margin-top: 40px;}
      }
    }

    div#TopBar div.expand-button {
      display: none !important;
    }
    
    div#TopBar div.arrow-left-button {
      position: absolute;
      left: 0;
      border-radius: 20px;
    }

    div#TopBar div.text {
      position: absolute;
      right: 0;
    }

    div#TopBar div.scene-materials {
      position: absolute;
      right: 82px;
    }

    div#TopBar div.scene-list {
      display: none;
    }

    div#TopBar div.undo-button {
      position: absolute;
      left: 40px;
      border-radius: 20px;
    }

    div#TopBar div i {
      justify-self: start;
    }

    div#TopBar div i:hover,
    div#TopBar div.scene-materials:hover,    
    div#TopBar div.text:hover {
      opacity: .8;
      box-shadow: 3px 3px 3px 0px #c6c6c6;
      border-radius: 20px;
    }

    img {
      display: block !important;
      align-self: center;
    }
  }

  img {
    height: 24px;
    display: none;
  }

  div#TopBar {
    position: absolute;
    display: flex;
    //margin-top: 40px;
    flex: 1;
    flex-wrap: no-wrap;
    height: 40px;
    width: 100vw;
    align-items: start;
    justify-content: space-between;
    background-color: ${props => props.backgroundColor};
    box-shadow: 0 0.2px 4px rgba(0, 0, 0, 0.10);
  }

  div#TopBar div {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    color: ${props => props.fontColor} !important;
    font-weight: 700;
  }

  div#TopBar div.text {
    margin-right: 16px;
    font-size: 12px;
    height: auto;
    padding: 8px 12px;
    border-radius: 16px;
    cursor: pointer;
    align-self: center;
    background-color: ${props => props.brandColor};
    color: ${props => props.backgroundColor} !important;
  }

  div#TopBar div i {
    font-size: 18px;
    padding: 8px;
    color: ${props => props.fontColor};
    cursor: pointer;
  }

  div#TopBar div i:hover {
    opacity: .8;
    border-radius: 20px;
  }

  div#TopBar div.selected {
    border-top: 2px solid ${props => props.brandColor};
    color: ${props => props.brandColor};
  }

  div#TopBar div.scene-materials {
    margin-right: 32px;
      font-size: 12px;
      height: auto;
      padding: 8px 12px;
      border-radius: 16px;
      cursor: pointer;
      align-self: center;
  }

  @media only screen and (max-width: 1023px){
    div#TopBar div.scene-materials {
    display: none !important;
  }
  }


`
