import * as types from "../types";

const initialState = {
  isLoggedIn: false,
  token: false,
  user: {},
  onboarding: false,
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_SUCCESS: {
      const newState = { ...state };
      newState.isLoggedIn = true;
      newState.token = action.payload.data.token;
      newState.user = action.payload.data.user;
      newState.onboarding = false; // action.payload.data.user.firstAccess
      return newState;
    }
    case types.LOGIN_FAILURE: {
      const newState = { ...initialState };
      return newState;
    }
    case types.SET_ONBOARDING: {
      const newState = { ...state };
      newState.onboarding = action.payload;
      return newState;
    }
    case types.UPDATE_USER_PHONE: {
      const newState = { ...state };
      newState.user.phone = action.payload;
      return newState;
    }
    case types.SAVE_USER: {
      const newState = { ...state };
      newState.user = action.payload.user;
      newState.isLoggedIn = true;
      return newState;
    }
    default: {
      return state;
    }
  }
}
