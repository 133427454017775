import axios from "axios";
import { toast } from "react-toastify";
import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const api = axios.create({
  //baseURL: "http://localhost:4000/api/",
  //baseURL: 'https://criadecor.com.br/api/'
  // baseURL: "/api/",
  baseURL: process.env.REACT_APP_API_URL,
});

const AxiosComponent = (props) => {
  useEffect(() => {
    const handleResult = async (result) => {
      return result;
    };

    api.interceptors.request.use(async (config) => {
      // let isLoggedIn = JSON.parse(localStorage.getItem("persist:appcria"));
      // if (isLoggedIn)
      //   isLoggedIn = JSON.parse(isLoggedIn.loginReducer).isLoggedIn;

      const localToken = localStorage.getItem("@CRIA_TOKEN");
      const sessionToken = sessionStorage.getItem("@CRIA_TOKEN");

      if (localToken || sessionToken) {
        config.headers["x-access-token"] = localToken
          ? localToken
          : sessionToken;
      }
      return config;
    });

    const handleErrors = (error) => {
      if (
        error.response.status === 401 ||
        error.response.data.message === "User ID not found or invalid session!"
      ) {
        localStorage.removeItem("persist:appcria");
        localStorage.removeItem("@CRIA_TOKEN");

        if (
          error.response.config.url !== "signin/" &&
          error.response.config.url !== "global/sendwhatsapp"
        )
          window.location.replace("/login/?token=invalid");
      }
      return Promise.reject(error);
    };
    const interceptor = api.interceptors.response.use(
      handleResult,
      handleErrors
    );
    return () => api.interceptors.response.eject(interceptor);
  }, []);

  return <>{props.children}</>;
};
export default api;
export { AxiosComponent };
