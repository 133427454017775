import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//import { Offline, Online } from "react-detect-offline";

import * as contractActions from "./../../store/modules/contract/actions";

// Styles
import { Loading, OfflineDiv } from "./styles";

// Components
import RoomsList from "./components/RoomsList";
import NavigationBar from "../../components/NavigationBar";

import api from "../../services/api";

const NewRoom = () => {
  const history = useHistory();

  const [isConnectionUnstable, setIsConnectionUnstable] = useState(false);
  const [roomsData, setRoomsData] = useState();
  const [isRoomLoad, setIsRoomLoad] = useState(true);

  const isRoomsLoading = useSelector(
    (state) => state.contractReducer.contractRooms.isLoading
  );
  let contractId = useSelector(
    (state) => state.contractReducer.contract.contractId
  );

  const backgroundColor = useSelector(
    (state) => state.contractReducer.contract.config.backgroundColor
  );
  const logoPath = useSelector(
    (state) => state.contractReducer.contract.config.logoPath
  );

  let TestUser = useSelector((state) => state.loginReducer.user.test);
  let UserId = useSelector((state) => state.loginReducer.user.id);

  const dispatch = useDispatch();

  useEffect(() => {
    if (navigator.connection) {
      const handleConnectionChange = () =>
        navigator.connection.downlink < 0.45
          ? setIsConnectionUnstable(true)
          : setIsConnectionUnstable(false);
      handleConnectionChange();
      navigator.connection.onchange = handleConnectionChange;
    }
  }, []);

  useEffect(() => {
    if (TestUser) {
      contractId = "61031cdbf770c825dca7647b";
    }

    if (isRoomsLoading) {
      // contractId && dispatch(contractActions.getContractRooms(contractId));

      if (contractId) {
        api.get(`/room/contract/${contractId}`).then(({ data }) => {
          if (data.rooms) setRoomsData(data.rooms);

          setIsRoomLoad(false);
        });
      }
    }
  }, [contractId]);

  return (
    <>
      {isRoomLoad ? (
        <Loading>
          <img src={logoPath} alt="Logo Criadecor" />
        </Loading>
      ) : (
        <RoomsList history={history} roomsData={roomsData} />
      )}
      {/*<Offline>
          <OfflineDiv backgroundColor={backgroundColor}>
              Você está offline. Verifique sua conexão!
              <i className="fas fa-wifi"></i><i className="fas fa-slash"></i>
          </OfflineDiv>
      </Offline>
      <Online>
        {isConnectionUnstable &&
          <OfflineDiv>
            Conexão instável. Verifique sua conexão!
            <i className="fas fa-wifi"></i><i className="fas fa-slash"></i>
          </OfflineDiv>
        }
      </Online>*/}
      <NavigationBar viewIndex={2}></NavigationBar>
    </>
  );
};

export default NewRoom;
