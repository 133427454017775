import React from 'react'
import { useSelector } from 'react-redux'

import { Container } from './styles'

const RoomTypeThumb = (props) => {
    const onboarding = useSelector(state => state.loginReducer.onboarding)

    const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
    const fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)

    let imgSrc = ""
    window.innerWidth >= 1024 ? imgSrc = props.DesktopThumbSrc : imgSrc = props.MobileThumbSrc

    return (
        <Container className={onboarding && props.roomTypeIndex == 0 ? "active" : "" } onClick={props.onThumbClick} backgroundColor={backgroundColor} fontColor={fontColor}>
            <img data-id={props.typeId} src={imgSrc} alt=""/>
            <div className="card-shadow"></div>
            <h4>{props.title}</h4>
            { window.innerWidth >= 1024 && onboarding && props.roomTypeIndex != 0 && <div className="inactive"></div>}
        </Container>
    )

}

export default RoomTypeThumb