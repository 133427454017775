import styled from 'styled-components'

export const Container = styled.div`
padding: 24px;
background-color: ${props => props.backgroundColor};
box-shadow: 2px 2px 4px 1px rgb(0 0 0 / 20%);
border-radius: 15px;
height: 60vh;

h1 {
  margin: 0px;
  font-size: 1.5em;
  color: ${props => props.fontColor};
}

input {
  padding: 4px 8px;
  border-radius: 15px;
  border: 1px #bebebe solid;
}

.users {
  border: 1px solid #EAEAEA;
  border-radius: 15px;
  height: calc(90% - 56px);
  display: inline-flex;
  width: 100%;
}

.userList {
  height: calc(100% - 52px);
  width: 100%;
}

.userList .MuiDataGrid-root {
  border-radius: 15px;
}
.title-new {
  display: inline-flex;
  margin-bottom: 14px;
  width: 100%;
  justify-content: space-between;
  
}

@media screen and (max-height: 720px){
  .userList {
    height: calc(100% - 124px);    
  }
}


@media screen and (max-width: 1366px){
  .title-new {
    flex-direction: column;
  }

  .filtros{
    margin: 10px 0;
  }
  
  height: 60vh;
  
}

.title-left-side {
  display: flex;
}

.new-btn, .export-btn {
  padding: 6px 18px;
  border-radius: 10px;
  border: none;
  margin: 0px 12px;
  font-size: 14px;
  /* box-shadow: 1px 1px 4px 1px rgb(0 0 0 / 20%); */
  font-family: 'Montserrat',sans-serif;
  font-weight: 500;
  color: #fff;
  background-color: #32CD32;  
}

.export-btn {
  background-color: #2196F3;
}

.new-btn :hover {
  opacity: .7;
}

.header-buttons {
  display: flex;
  align-items: center;
}

.filtros {
  display: flex; 
  align-items: center;
}

.filtros form {
  display: contents;
  flex-direction: column;
  margin-left: 2rem;
}

/*
.filtros input {
  width: 154px;
}
*/

.search {
  width: 124px;
}

.filtros form label, .type-container label {
  margin-top:0;
  margin-left: 16px;
  margin-right: 8px;
}

.type-container {
  display: contents;
  flex-direction: column;
  justify-content: space-between;
}

.dropTipos{
  width: 124px;
}

.dropTipos .Dropdown-control {
  border-radius: 25px;
  padding: 6px 10px;
}

`


