import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Container } from './styles'

const SlideUpModal = (props) => {

    const [innerHeight, setInnerHeight] = useState(window.innerHeight)
    const brandColor = useSelector(state => state.contractReducer.contract.config.brandColor)
    const backgroundColor = useSelector(state => state.contractReducer.contract.config.backgroundColor)
    const fontColor = useSelector(state => state.contractReducer.contract.config.fontColor)

    useEffect(() => {
        const handleResize = () => setInnerHeight(window.innerHeight)
        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    return (
        <Container
            maxHeight={props.display ? (innerHeight - 120) + "px" : "0"}
            bottom={props.display ? "40px" : "0"}
            fitContent={props.fitContent ? "100%" : (innerHeight - 78) + "px"}
            backgroundColor={backgroundColor}
            fontColor={fontColor}
            brandColor={brandColor}
        >
            <div className="title-bar">
                <h3>{props.title}</h3>
                <i className="fas fa-times" onClick={props.onCloseClick}></i>
            </div>
            <div className="main-content">
                {props.mainContent}
            </div>
        </Container >
    )
}

export default SlideUpModal